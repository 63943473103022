import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";
import tw from "twrnc";
import ColaboradorSelect from "../ColaboradorSelect";
import { Controller, useFormContext } from "react-hook-form";
import ContainerStep from "./ContainerStep";

const ContentStep3 = ({ currentStep, asignado }) => {
  const ctx = useFormContext();
  let {
    control,
    formState: { errors },
    getValues
  } = ctx;

  return (
    <ContainerStep step={3} currentStep={currentStep}>
    <View
      style={tw`selef-center flex flex-col gap-4 border border-gray-200 rounded-md p-4`}
    >
      <Text style={tw`font-semibold`}>Colaboradores Asignados</Text>
      <Controller
        control={control}
        rules={{
          required: "Indique al menos un colaborador",
          valueAsNumber: {
            value: true,
            message: "Indique al menos un colaborador",
          },
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <ColaboradorSelect colaborador={asignado} onChange={onChange} />
            {errors?.asignado_id && (
              <Text style={tw`text-red-700`}>
                Error: {errors.asignado_id?.message}
              </Text>
            )}
          </>
        )}
        name="asignado_id"
      />
    </View>
    </ContainerStep>
  );
};

export default ContentStep3;
