import { useAtom } from "jotai";
import React from "react";
import { View, SafeAreaView, useWindowDimensions } from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import { PageContainer } from "../../components/PageContainer";
import { Colores } from "../../constants/colores";
import PuestoForm from "../../forms/PuestoForm";

import { awesomeAlertAtom } from "../../store/store";

export default function CrearPuestoScreen(props) {
  const [awsomeAlert, _] = useAtom(awesomeAlertAtom);
  const { navigation, departamentoId, puesto } = props.route.params;
  const {width} = useWindowDimensions()

  return (
    <PageContainer paddingMd>
      <View style={[{ flex: 1, padding: 16, backgroundColor:'#fFF'},  width > 758 && {borderRadius: 20}]}>
        <PuestoForm
          navigation={navigation}
          departamentoId={departamentoId}
          puesto={puesto}
        />
      </View>
      <AwesomeAlert
        show={awsomeAlert.show}
        showProgress={false}
        title={awsomeAlert.title}
        message={awsomeAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={awsomeAlert.showConfirmButton}
        confirmText="Aceptar"
        customView={awsomeAlert.customView}
        confirmButtonColor={Colores.success}
        onDismiss={awsomeAlert.onDismiss}
        onCancelPressed={awsomeAlert.onCancelPressed}
        onConfirmPressed={awsomeAlert.onConfirmPressed}
      />
    </PageContainer>
  );
}
