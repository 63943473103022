import React from "react";
import Svg, { G, Path } from "react-native-svg"

const LineasBackgroud = (props) => {
  return (
    <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 1200 250"
    {...props}
  >
    <G stroke="#7F7FEF" strokeMiterlimit={10} strokeWidth={2.741} opacity={0.3}>
      <Path d="M1845 107.251c7.79 78.53-87.07 141.409-180.36 150.232-93.28 8.822-185.13-19.388-276.23-38.847-91.11-19.229-194.37-28.213-273.16 14.333-100.82 54.541-129.047 175.544-232.577 226.61-80.676 39.768-182.502 23.433-261.685-11.104-44.78-19.502-82.052-50.9-124.612-73.373C303.217 273.271 298.939 79.037 132.314 44.171c-180.31-37.29-282.374 33.577-319.011 83.866-46.908 64.462-128.201 233.304 45.829 358.892C-70.057 537.957-.643 591.964 50.026 657.537c75.868 98.242 68.522 244.052-37.693 319.253-72.834 51.57-181.802 67.39-232.851 135.03-34.401 45.48-32.75 105.55-7.157 155.25 25.321 49.47 71.833 89.69 122.523 122.59" />
      <Path d="M1840.92 103.303c4.21 80.799-90.41 146.902-186.45 157.31-93.84 10.197-187.33-18.027-278.15-38.864-90-20.83-190.5-32.32-268.97 6.317-100.23 49.255-133.06 164.004-236.308 214.152-82.321 39.753-182.82 28.262-263.959-2.151-46.717-17.448-87.292-47.266-132.041-70.218C285.675 273.113 278.599 85.067 115.807 50.695-59.298 13.912-160.779 80.873-198.779 130.23c-48.262 62.609-125.663 225.504 38.517 349.163 67.796 51.001 134.46 105.903 184.583 171.241 73.695 95.921 67.767 236.452-32.687 311.246-69.544 51.601-174.699 69.981-225.465 136.711-34.951 45.7-35.485 104.83-13.729 154.49 22.026 50.13 64.681 92.39 113.432 127.58" />
      <Path d="M1836.85 99.124c.62 82.838-93.75 152.394-192.82 164.615-94.4 11.573-189.27-16.433-280.35-39.113-88.89-22.201-186.62-36.197-264.51-1.696-99.628 43.968-136.793 152.695-240.033 201.923-83.693 39.971-183.139 33.321-266.231 6.572-48.652-15.625-92.531-43.862-139.198-66.831C268.682 272.729 258.26 91.095 99.027 57.216-71.151 21.167-171.5 84.226-210.864 132.88c-49.342 60.758-123.124 217.473 31.202 339.432 64.779 51.204 128.969 107.005 178.001 171.642 71.25 93.599 67.012 229.083-27.68 303.238-66.257 51.863-167.595 72.577-218.353 138.387-35.5 45.7-38.494 104.11-20.029 153.98 18.728 51.02 57.53 95.08 104.067 132.55" />
      <Path d="M1833.05 95.178c-3.23 84.874-97.09 157.885-198.92 171.692-94.96 12.719-191.47-15.072-282.26-39.36-87.78-23.571-182.75-40.074-260.33-9.942-98.757 38.915-140.524 141.158-243.757 189.695-85.34 40.186-183.459 38.381-268.505 15.525-50.589-13.571-97.772-40.227-146.628-63.677C251.695 271.885 238.197 96.896 82.523 63.509c-164.978-35.081-264.742 23.842-305.468 71.563-50.424 59.138-120.588 209.904 23.887 329.933 61.764 51.177 122.928 108.101 171.416 172.273 68.804 91.276 66.256 221.713-22.674 295.23-62.97 52.122-160.493 75.402-210.966 140.062-35.776 45.92-41.503 103.4-26.604 153.46 15.433 51.68 50.379 97.78 94.976 137.53" />
      <Path d="M1828.97 91.228c-6.81 87.143-100.43 163.378-205.01 178.771-95.52 14.094-193.41-13.479-284.45-39.611-86.4-24.938-178.88-44.18-255.87-17.954-98.164 33.628-144.26 129.619-247.487 177.467-86.984 40.171-184.053 43.437-271.05 24.245-52.802-11.521-103.012-36.823-154.058-60.523-176.335-82.812-193.459-250.932-345.3-283.824-159.778-34.114-258.958 20.903-301.047 67.691-51.503 57.288-118.327 202.332 16.574 320.204 58.747 51.38 117.163 108.969 164.832 172.903 66.357 88.953 65.5 214.344-17.67 287.453-59.683 52.382-153.389 77.996-203.58 141.74-36.327 46.15-44.512 102.68-32.904 152.94 12.135 52.57 43.499 100.71 85.611 142.51" />
      <Path d="M1824.9 87.052c-10.4 89.18-103.77 168.869-211.11 186.078-96.08 15.239-195.61-11.889-286.37-39.858-85.29-26.079-175.27-48.289-251.68-25.97-97.57 28.341-148.27 118.079-250.94 165.011-88.632 40.386-184.373 48.496-273.322 32.968-55.013-9.47-108.253-33.189-161.214-57.366-171.989-78.401-192.19-239.646-340.748-271.818-154.851-33.38-253.173 17.733-296.626 63.819-52.311 55.67-116.068 194.989 9.26 310.474 56.004 51.585 111.397 110.067 158.25 173.304 64.185 86.633 64.742 207.205-12.664 279.445-56.396 52.641-146.011 80.594-196.194 143.411-36.876 46.38-47.523 102.2-39.204 152.43 8.564 53.46 36.348 103.41 76.52 147.49" />
      <Path d="M1821.1 83.105c-13.98 91.219-107.11 174.361-217.48 193.153-96.64 16.385-197.55-10.295-288.29-39.875-84.18-27.45-171.4-52.166-247.5-34.215-96.969 23.054-151.998 106.54-254.663 152.782-90.278 40.602-185.242 53.781-275.595 41.921-56.951-7.416-113.492-29.785-168.644-54.212-167.093-74.215-191.193-228.592-335.92-260.04-149.651-32.412-247.114 14.567-291.929 59.721-53.391 53.819-113.534 187.649 1.945 300.974 52.984 52.018 105.632 110.936 151.664 173.935 61.737 84.54 63.987 199.835-7.657 271.436-53.108 52.902-138.905 82.96-188.807 145.095-37.427 46.6-50.532 101.47-45.779 151.9 5.267 54.35 29.197 106.11 67.155 152.47" />
      <Path d="M1817.02 78.927c-17.84 93.486-110.45 179.854-223.57 200.232-97.19 17.53-199.75-8.705-290.48-40.125-83.07-28.821-167.53-56.273-243.31-42.231-96.378 17.768-155.736 95.002-258.394 140.554-91.923 40.587-185.839 59.067-277.868 50.644-59.161-5.366-118.456-26.378-175.799-51.055-162.468-70.262-190.198-217.31-331.365-248.265-144.727-31.447-241.058 11.631-287.235 55.852-54.473 52.199-111.551 180.534-5.369 291.244 49.967 52.221 99.866 112.034 145.082 174.336 59.565 82.219 63.503 192.697-2.376 263.431-49.824 53.391-131.802 85.554-181.422 146.766-37.977 46.83-53.542 100.99-52.078 151.39 1.694 55.24 22.044 108.8 57.79 157.45" />
      <Path d="M1812.95 74.976c-21.42 95.524-113.79 185.116-229.67 207.31-97.75 18.675-201.96-7.115-292.39-40.373-81.97-29.961-163.66-60.149-239.13-50.246-95.784 12.481-159.747 83.462-262.125 128.326-93.569 40.802-186.434 64.354-280.415 59.594-61.373-3.315-123.695-22.974-183.229-47.901C168.698 265.152 137.062 125.663-.544 94.97c-139.525-30.71-235.272 8.461-282.812 51.749-55.278 50.351-109.296 173.652-12.684 281.745 47.222 52.656 94.101 112.903 138.771 174.969 57.121 79.666 62.743 185.788 2.63 255.423-46.538 53.881-124.698 88.15-174.035 148.444-38.526 46.82-56.826 100.27-58.379 150.88-1.877 56.12 14.891 111.72 48.699 162.42" />
      <Path d="M1809.15 70.803c-25.01 97.563-117.13 190.608-236.04 214.615-98.31 19.821-203.9-5.521-294.59-40.623-80.85-31.331-159.78-64.255-234.67-58.489-95.183 7.425-163.472 71.924-265.842 115.868-95.214 40.787-187.352 74.929-282.687 68.317-63.283-4.252-128.66-19.568-190.384-44.744-152.669-62.581-188.209-194.743-321.984-224.711-134.325-29.743-229.215 5.525-278.391 47.878-56.36 48.731-107.315 166.767-19.998 272.015 44.203 53.089 88.612 113.773 132.189 175.37 54.949 77.346 62.259 178.651 7.637 247.415-43.254 54.371-117.595 90.745-166.924 150.123-39.075 47.043-60.11 99.773-64.952 150.353-5.449 57.01 8.013 114.43 39.333 167.4" />
      <Path d="M1805.07 66.855c-28.86 99.828-120.46 195.869-242.13 221.692-98.87 20.967-206.1-3.93-296.51-40.64-79.75-32.471-155.91-68.132-230.48-66.504-94.318 2.14-167.485 60.383-269.575 103.639-96.86 41.003-187.63 75.387-284.96 77.27-65.8 1.247-133.9-16.163-197.814-41.59C136.106 261.869 96.661 137.265-33.555 107.79c-129.398-29.009-223.156 2.358-273.696 44.008-57.166 46.883-105.61 160.11-27.311 262.286 41.181 53.752 82.844 114.871 125.604 176.001 52.503 75.022 61.773 171.743 12.643 239.407-39.694 54.863-110.491 93.339-159.538 151.8-39.625 47.268-63.395 99.288-71.252 149.838-9.02 57.9.862 117.12 30.243 172.38" />
      <Path d="M1800.99 62.676c-32.44 101.867-123.8 201.362-248.22 228.77-99.43 22.112-208.04-2.107-298.42-40.887-78.64-33.842-152.31-72.242-226.3-74.52-93.724-3.146-171.221 48.845-273.304 91.411-98.505 40.988-188.229 80.904-287.232 85.993-67.74 3.53-138.864-12.757-205.244-38.436C119.678 259.65 76.324 142.834-50.332 113.85c-124.198-28.04-217.099-.578-269.273 39.907-58.248 45.263-104.182 153.68-34.627 252.786 38.16 54.415 77.08 115.74 119.022 176.402 50.331 72.702 61.287 164.836 17.647 231.629-36.409 55.353-103.387 95.934-152.151 153.477-40.176 47.499-66.679 98.569-77.553 149.319-12.866 58.79-6.289 119.82 20.878 177.36" />
      <Path d="M1797.19 58.73c-36.02 103.905-127.13 206.624-254.32 236.078-99.98 23.027-210.24-.516-300.61-41.137-77.54-34.983-148.44-76.118-222.11-82.765-93.131-8.203-175.23 37.304-277.033 79.182-100.152 41.203-188.826 86.421-289.78 94.944-69.953 5.811-143.829-9.351-212.4-35.279-137.415-51.86-184.948-161.121-307.774-189.379-118.996-27.304-211.041-3.515-264.852 36.035-59.055 43.645-102.755 147.481-41.94 243.056 34.865 55.076 71.314 116.839 112.437 177.033 48.159 70.381 61.073 158.162 22.654 223.621-32.852 56.075-96.283 98.53-144.765 155.154-40.724 47.487-69.964 98.077-84.127 148.797-16.438 59.68-13.443 122.75 11.787 182.34" />
      <Path d="M1793.12 54.782c-39.89 106.171-130.48 211.885-260.69 243.153-100.55 24.173-212.46 1.305-302.53-41.384-76.43-36.123-144.57-79.995-217.66-90.778-92.53-13.49-178.956 25.536-280.752 66.724-101.797 41.188-189.427 92.167-292.052 103.666-72.167 8.092-149.066-6.177-219.829-32.125C87.369 255.677 35.653 154.201-83.613 126.435c-114.069-26.57-204.985-6.451-260.155 31.936-59.862 41.797-101.605 141.509-49.256 233.556 31.841 55.969 65.549 117.707 105.854 177.434 45.713 68.058 60.859 151.487 27.66 215.613-29.295 56.798-89.179 101.125-137.379 156.832-41.274 47.716-73.246 97.354-90.427 148.284-20.283 60.56-20.32 125.44 2.422 187.31" />
      <Path d="M1789.04 50.602c-43.46 108.21-133.81 217.148-266.78 250.231-101.1 25.088-214.66 2.895-304.45-41.631-75.59-37.267-140.69-84.102-213.47-98.794-91.935-18.776-182.964 13.996-284.481 54.496-103.44 41.173-190.024 97.684-294.323 112.389-74.108 10.606-154.029-3-226.985-28.968-127.06-45.094-182.685-138.551-298.666-165.828-108.87-25.602-198.926-9.618-255.734 28.065-60.67 40.178-100.729 135.534-56.57 223.826 28.272 56.627 60.059 118.578 99.27 178.065 43.541 65.738 60.645 144.813 32.667 207.605-25.464 57.522-82.076 103.72-129.993 158.509-41.825 47.941-76.806 96.863-96.727 147.763-24.129 61.45-27.472 128.14-6.669 192.3" />
      <Path d="M1785.24 46.658c-47.05 110.249-137.15 222.41-272.88 257.309-101.66 26.234-216.87 4.716-306.64-41.651-74.48-38.637-136.81-87.978-209.283-107.039-91.067-23.83-186.699 2.457-287.934 42.27-105.086 41.158-190.625 103.431-296.597 121.342-76.322 12.887-158.992.176-234.415-25.814-121.88-41.825-181.69-127.268-293.839-154.05-103.943-24.637-193.144-12.326-251.587 24.191-61.477 38.56-100.401 129.555-64.157 214.094 24.698 57.745 54.293 119.676 92.96 178.698 41.368 63.418 60.705 138.141 37.672 199.597-21.632 58.247-75.243 106.083-122.88 160.184-42.375 48.166-80.363 96.141-103.3 147.011-27.974 62.34-34.623 130.84-16.034 197.28" />
      <Path d="M1781.17 42.478c-50.91 112.515-140.49 227.672-278.98 264.617-102.22 27.379-219.08 6.537-308.56-41.899-73.38-39.547-132.94-91.854-205.096-115.054-90.471-29.117-190.706-9.083-291.936 30.039-106.732 41.373-191.225 109.178-299.143 130.063-78.535 15.167-163.952 3.122-241.57-22.658C39.183 249.029-24.811 171.602-133.4 145.312c-98.742-23.899-186.811-15.49-246.616 20.094-62.287 37.173-100.077 124.036-71.198 204.597 20.852 58.631 48.528 120.545 86.377 179.099 39.198 60.867 60.765 131.469 42.679 191.589-17.802 58.972-68.14 108.677-115.495 161.861-42.923 48.161-83.645 95.423-109.599 146.498-32.092 62.99-41.777 133.77-25.125 202.25" />
      <Path d="M1777.09 38.529C1722.6 153.082 1633 271.459 1491.75 310.22c-103.05 28.291-221.29 8.127-310.75-42.149-72.27-40.688-129.34-95.734-200.642-123.067-89.878-34.174-194.438-20.852-295.389 17.813-108.101 41.131-191.549 114.697-301.141 138.788-80.477 17.681-168.639 6.071-249-19.503C23.581 246.586-44.875 177.632-149.629 151.607c-93.544-22.703-180.755-18.197-242.195 16.222-63.094 35.555-100.297 118.051-78.786 194.865 16.731 59.744 43.038 121.415 79.793 179.729 37.026 58.547 61.099 124.799 47.685 183.581-13.696 59.7-61.036 111.273-108.108 163.539-43.474 48.386-87.203 94.7-115.9 145.977-35.938 63.87-48.654 136.47-34.488 207" />
      <Path d="M1773.29 34.354c-58.07 116.592-147.43 237.963-291.44 278.77-103.61 29.207-223.5 9.948-312.66-42.396-71.44-41.831-125.47-99.84-196.459-131.313-89.283-39.23-198.445-32.392-299.114 5.355-110.021 41.344-192.149 120.444-303.688 147.738-82.419 20.195-173.6 9.018-256.43-16.348C7.433 243.68-65.208 182.742-166.404 157.439c-88.617-21.967-174.699-21.133-237.772 12.121-63.903 33.937-100.794 112.294-86.1 185.135 12.063 60.622 37.274 122.284 73.21 180.13 34.854 56.226 61.708 118.132 52.964 175.806-9.317 60.429-53.933 113.868-100.722 165.215-44.024 48.612-90.76 93.978-122.474 145.464-39.782 64.52-55.806 139.16-43.579 211.98" />
      <Path d="M1769.21 30.407c-61.92 118.857-150.76 243.224-297.53 286.077-104.17 30.353-225.71 11.769-314.58-42.643-70.34-42.971-121.6-103.717-192.274-139.328-88.688-44.517-202.452-44.163-302.842-6.874-111.938 41.097-192.751 126.421-306.236 156.689-84.632 22.476-178.285 11.737-263.586-13.192C-8.723 241.695-85.55 189.001-182.913 164.194c-83.417-21.001-168.641-24.07-233.077 8.251-64.71 32.319-101.562 106.305-93.415 175.636 7.12 61.498 31.783 123.154 66.625 180.761 32.685 53.675 62.319 111.235 57.971 167.797-4.664 61.162-47.101 116.231-93.337 166.893-44.846 48.605-94.042 93.258-128.774 144.948-43.901 65.41-62.957 141.85-52.943 216.95" />
      <Path d="M1765.14 26.456c-65.51 120.896-154.38 248.254-303.9 293.153-104.73 31.268-227.92 13.59-316.78-42.663-69.5-44.114-117.72-107.594-187.812-147.341-88.091-49.804-206.184-55.701-306.569-19.102-113.31 41.314-193.075 131.94-308.508 165.412-86.573 24.989-183.243 14.453-271.017-9.808-95.43-26.401-176.717-70.851-270.521-95.167-78.49-20.265-162.312-26.774-228.656 4.38-65.242 30.474-102.05 99.628-100.179 165.681 1.905 62.371 26.293 124.025 60.043 181.161 30.512 51.356 62.927 104.568 62.977 159.79-.008 61.664-39.997 118.826-85.95 168.571-45.396 48.829-97.874 92.533-135.348 144.425-47.745 66.062-70.111 144.782-62.309 221.932" />
      <Path d="M1761.34 22.282c-69.37 123.162-157.72 253.516-310 300.231-105.55 32.18-230.4 15.408-318.69-42.911-68.4-45.254-113.85-111.7-183.628-155.586-87.498-54.86-210.19-67.472-310.296-31.33C523.77 133.985 445.325 230.375 327.944 267.05c-88.515 27.503-187.926 16.942-278.173-6.651-90.247-23.593-175.448-59.565-265.693-83.388-73.291-19.299-156.255-29.711-223.96.28-66.327 29.313-103.912 93.399-108.044 156.407-4.132 63.007 20.803 124.896 53.733 181.795 28.34 49.034 63.813 97.443 67.983 151.781 4.647 62.166-32.894 121.421-78.564 170.248-45.947 49.055-101.157 91.813-141.648 143.908-52.411 66.71-77.811 147.48-71.948 226.91" />
    </G>
  </Svg>
  );
};

export default LineasBackgroud;
