import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { Colores } from '../constants/colores';

export default function ResultadoModal({ success, title="", message }) {

if (title==""){
  if (success){
    title =  "¡Éxito!";
  } else {
    title = "Error"
  }
}

let icon;

if (success){
  icon = <Ionicons name="checkmark-circle-outline" size={42} color={Colores.success} />;
} else {
  icon = <Ionicons name="close-circle-outline" size={42} color={Colores.danger} />;
}

  return (
    <View style={styles.resultadoContainer}>
      {icon}
      <Text style={styles.resultadoTitulo}> {title} </Text>
      <Text style={styles.resultadoMensaje}>{message}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  resultadoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  resultadoTitulo: {
    fontSize: 18,
    fontWeight: "bold",
  },
  resultadoMensaje: {
    textAlign: "center",
  },
});
