import React, { useState } from "react";
import {
  Text,
  View,
  TextInput,
  Button,
  StyleSheet,
  ActivityIndicator,
  Image,
  Pressable,
  Switch,
  TouchableOpacity,
  ScrollView,
  useWindowDimensions
} from "react-native";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as DocumentPicker from "expo-document-picker";
import RNPickerSelect from "react-native-picker-select";
import tw from 'twrnc';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import { nuevoPlato } from "../services/apiClient";

import AwesomeAlert from "react-native-awesome-alerts";
import ResultadoModal from "../components/ResultadoModal";
import MensajeError from "../components/MensajeError";
import { Colores } from "../constants/colores";
import { useNavigation } from "@react-navigation/native";


export const PlatoForm = (props) => {
  const navigator = useNavigation();
  const [foto, setfoto] = useState();
  const [habilitado, setHabilitado] = useState(1);
  const [tipoPlato, settipoPlato] = useState("comida");
  const queryClient = useQueryClient();
  const {width} = useWindowDimensions();

  const alertInitialState = {
    show: false,
    title: "",
    message: "",
    showConfirmButton: false,
  };
  const [mostrarAlert, setmostrarAlert] = useState(alertInitialState);

  const toggleHabilitadoSwitch = () =>
    setHabilitado((previousState) => !previousState);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const resetForm = () => {
    reset({ nombre: "", descripcion: "" });
    setfoto();
    setHabilitado(0);
  };

  const nuevoPlatoMutate = useMutation(nuevoPlato, {
    onSuccess: async (response) => {
      queryClient.invalidateQueries("platos");
      resetForm();
      //mostral mostrar modal
      setmostrarAlert({
        show: true,
        showConfirmButton: true,
        customView: (
          <ResultadoModal success={true} message="El plato fue creado" />
        ),
      });
    },
    onError: async (error) => {
      // console.log(error.response.data.errors.foto[0]);
      //TODO: mostrar errores en campos
      setmostrarAlert({
        show: true,
        customView: <ResultadoModal success={false} message={error.response.data.errors.foto[0]} />,
      });
    },
  });

  const formData = new FormData();
  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: ["image/jpeg", "image/png"],
    });
    setfoto(result);
  };

  const onSubmit = (data) => {
    if (foto) {
      formData.append("foto", foto?.file);
    }
    //se cargan el resto de campos del formulario en formData
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }
    nuevoPlatoMutate.mutate({ tipoPlato, formData });

  };

  //TODO: agregar un select para cargar el tipo de menu (vegetariano...etc)
  return (
    <>
    <View style={[tw`px-5 bg-white`,{borderRadius: width >=768 ? 20 : 0}]}>
        <View>
          {width >=768 && (
            <Text style={tw`text-lg font-semibold mb-5 mt-10 ml-10`}>Nuevo Plato</Text>
          )}
        </View>
      <ScrollView>
        <View style={width >=768 ? tw`px-20` : tw``}>
            <Text style={tw` text-gray-900 mt-3`}>Nombre</Text>
              <Controller
                control={control}
                rules={{
                  required: "Ingrese un nombre",
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={[tw` mt-2 px-3 py-1.5 text-base font-normal text-gray-700 border border-gray-300`,{backgroundColor:'#FFF', borderRadius:50}]}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    placeholder="Nombre"
                    value={value}
                  />
                )}
                name="nombre"
              />
            {errors?.nombre && <MensajeError message={errors.nombre?.message} />}
        </View>

        <View style={width >=768 ? tw`mt-3 px-20` : tw`mt-3`}>
            <Text style={tw`mb-2`}>Categoria</Text>
            <RNPickerSelect
              onValueChange={(value) => settipoPlato(value)}
              placeholder={{}}
              items={[
                { label: "Comida", value: "comida" },
                { label: "Guarnicion", value: "guarnicion" },
                { label: "Postre", value: "postre" },
              ]}
              value={tipoPlato}
            />
          </View>

      
      <View style={width >=768 ? tw`flex-row mt-5 px-20` : tw`flex-row mt-5` }>
          <View style={tw`flex-3`}>
            <Text>Agregar imagen</Text>
            <Text style={tw`text-gray-500 mt-1`}>Formato valido jpg o png</Text>
            <Pressable onPress={pickDocument} foreground={true}>
              {foto ? (
                <Image
                style={[tw`shadow-sm h-30 w-30 mt-3`,{resizeMode:'contain', borderRadius:16}]}
                source={{ uri: foto.uri }}
                />
              ) : ( 
                <View style={[tw`items-center justify-center h-30 w-30 shadow-sm border border-gray-300 mt-3`,{borderRadius:16}]}>
                <MaterialCommunityIcons name="image-plus" size={44} style={{color:Colores.primaryLight}} />
              </View>
              )}
            </Pressable>
          </View>
              
          <View style={tw`flex-2`}>
            <Text>Estado</Text>
            <Controller
              control={control}
              defaultValue={habilitado}
              name="habilitado"
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={tw`flex-row mt-5`}>
                  <Text style={value ? [tw`mt-1 mr-2 `,{color:'#009688'}] : tw`mt-1 mr-2 text-gray-700`}>{value ? 'Activo' : 'Inactivo'}</Text>
                  <Switch
                    trackColor={{ false: "#767577", true: "#81b0ff" }}
                    thumbColor={value ? "#f5dd4b" : "#f4f3f4"}
                    ios_backgroundColor="#3e3e3e"
                    onValueChange={(value) => {
                      toggleHabilitadoSwitch;
                      onChange(value ? 1 : 0);
                    }}
                    value={value}
                    style={tw`mt-1`}
                  />
                </View>
              )}
            />
          </View>
        </View>
          

        <View style={ width >=768 ? tw`mt-5 px-20` : tw`mt-5` }>
          <Text>Descripción</Text>
          <Controller
            control={control}
            rules={{
              required: "Ingrese una descripción",
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                style={[tw` mt-2 w-full px-3 py-1.5 h-40`,{backgroundColor:'#F3F3F3', borderRadius:10} ]}
                onBlur={onBlur}
                onChangeText={onChange}
                multiline={true}
                value={value}
              />
            )}
            name="descripcion"
          />
          {errors?.descripcion && (
            <MensajeError message={errors.descripcion?.message} />
          )}
        </View>  


        {nuevoPlatoMutate.isLoading ? (
          <ActivityIndicator size="small" style={tw`mt-7`} color={Colores.primary} />
        ) : (
          <View style={tw`justify-center items-center mb-20`}>
            <TouchableOpacity onPress={handleSubmit(onSubmit)} style={[tw`mt-7 px-20 py-2 shadow-md`,{backgroundColor:Colores.primary, borderRadius:15}]}>
              <Text style={tw`text-white text-center`}>Guardar</Text>
            </TouchableOpacity>
          </View>
  
        )}
      </ScrollView>
      </View>

      <AwesomeAlert
        show={mostrarAlert.show}
        showProgress={false}
        title={mostrarAlert.title}
        message={mostrarAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={mostrarAlert.showConfirmButton}
        confirmText="Aceptar"
        customView={mostrarAlert.customView}
        confirmButtonColor={Colores.success}
        onDismiss={() => {
          setmostrarAlert(alertInitialState);
          navigator.navigate('Administrar platos');
        }}
        onCancelPressed={() => {
          setmostrarAlert(alertInitialState);
        }}
        onConfirmPressed={() => {
          setmostrarAlert(alertInitialState);
          navigator.navigate('Administrar platos');
        }}
        
      />
    </>
  );
};
