import React, { useState } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";
import tw from "twrnc";
import { editarRoster } from "../services/apiClient";
import AwesomeAlert from "react-native-awesome-alerts";
import ResultadoModal from "../components/ResultadoModal";
import MensajeError from "../components/MensajeError";
import WebDatePicker from "../components/WebDatePicker";
import { useNavigation } from "@react-navigation/native";
import { Colores } from "../constants/colores";

export const RosterEditarForm = (props) => {
  const { roster } = props.route.params;
  const navigator = useNavigation();

  const { width } = useWindowDimensions();

  const alertInitialState = {
    show: false,
    title: "",
    message: "",
    showConfirmButton: false,
  };

  let valoresInicialesForm = {
    nombre: roster.nombre,
    ubicacion: roster.ubicacion,
    observaciones: roster.observaciones ?? "",
    fecha_inicio: roster.fecha_inicio,
    fecha_fin: roster.fecha_fin,
  };
  const [mostrarAlert, setmostrarAlert] = useState(alertInitialState);

  const toggleActivoSwitch = () => setactivo((previousState) => !previousState);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: valoresInicialesForm,
  });

  const cambiarVista = (roster) => {
    navigator.navigate("Editar Colaboradores Roster", {
      roster,
    });
  };

  const editarRosterMutate = useMutation(editarRoster, {
    onSuccess: async (response) => {
      queryClient.invalidateQueries("roster");
      queryClient.invalidateQueries("proximosRosters");
      queryClient.invalidateQueries("RostersFinalizados");
      queryClient.invalidateQueries("colaboradoresParaRosterQuery");
      setSeleccionados([]);
      colaboradoresParaRosterQuery.refetch();
      cambiarVista(roster);
      setmostrarAlert({
        show: true,
        showConfirmButton: true,
        customView: (
          <ResultadoModal success={true} message="El roster fue modificado" />
        ),
      });
    },
    onError: async (error) => {
      setmostrarAlert({
        show: true,
        customView: <ResultadoModal success={false} message="Hubo un error" />,
      });
    },
  });

  const formData = new FormData();

  const onSubmit = (data) => {
    console.log("data en onSubmit", data);

    //se cargan el resto de campos del formulario en formData
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      }
    }

    editarRosterMutate.mutate({ rosterId: roster.id, formData });
  };

  return (
    <View
      style={
        width >= 768 ? tw`px-15 py-10 bg-white rounded-xl` : tw`px-4 bg-white`
      }
    >
      {width >= 768 && (
        <View
          style={[
            tw`w-full justify-center bg-white`,
            { borderTopLeftRadius: 20, borderTopRightRadius: 20 },
          ]}
        >
          <Text style={tw`text-lg font-semibold text-gray-600 mb-5`}>
            Nuevo Roster
          </Text>
        </View>
      )}

      {/* primer input // NOMBRE */}
      <View style={tw`pl-5`}>
        <Text style={tw` mt-5 text-lg text-gray-900`}>Nombre</Text>
        <Controller
          control={control}
          rules={{
            required: "Indique un nombre",
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={[
                tw` mt-2 w-full px-3 py-2.5 text-gray-700 border`,
                { borderRadius: 50, borderColor: Colores.primary },
              ]}
              onBlur={onBlur}
              onChangeText={onChange}
              multiline={true}
              placeholder="Nombre"
              value={value}
            />
          )}
          name="nombre"
        />
        {errors?.roster && <MensajeError message={errors.roster?.message} />}
      </View>

      <View style={tw`pl-5`}>
        <Text style={tw` mt-5 text-lg text-gray-900`}>Ubicacion</Text>
        <Controller
          control={control}
          rules={{
            required: "Indique una ubicacion",
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={[
                tw` mt-2 w-full px-3 py-2.5 text-gray-700 border`,
                { borderRadius: 50, borderColor: Colores.primary },
              ]}
              onBlur={onBlur}
              onChangeText={onChange}
              multiline={true}
              placeholder="SAC, Jujuy"
              value={value}
            />
          )}
          name="ubicacion"
        />
        {errors?.roster && <MensajeError message={errors.roster?.message} />}
      </View>

      <View style={tw`pl-5`}>
        <Text style={tw` mt-5 text-lg text-gray-900`}>Observaciones</Text>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={[
                tw` mt-2 w-full px-3 py-2.5 text-gray-700 border`,
                { borderRadius: 50, borderColor: Colores.primary },
              ]}
              onBlur={onBlur}
              onChangeText={onChange}
              multiline={true}
              placeholder="Observaciones"
              value={value}
            />
          )}
          name="observaciones"
        />
        {errors?.roster && <MensajeError message={errors.roster?.message} />}
      </View>

      <View style={tw`pl-5`}>
        <Text style={tw` mt-5 text-lg text-gray-900`}>Fecha Inicio</Text>

        <Controller
          control={control}
          name="fecha_inicio"
          render={({ field: { value, onChange } }) => (
            <WebDatePicker
              selected={value}
              onChange={onChange}
              placeholderText="Fecha inicio"
              value={value}
            />
          )}
          defaultValue={null}
        />

        {errors?.roster && <MensajeError message={errors.roster?.message} />}
      </View>

      <View style={tw`pl-5`}>
        <Text style={tw` mt-5 text-lg text-gray-900`}>Fecha fin</Text>
        <Controller
          control={control}
          name="fecha_fin"
          render={({ field: { value, onChange } }) => (
            <WebDatePicker
              selected={value}
              onChange={onChange}
              placeholderText="Fecha fin"
              value={value}
            />
          )}
          defaultValue={null}
        />
        {errors?.roster && <MensajeError message={errors.roster?.message} />}
      </View>

      <View style={tw`items-center justify-center mb-10`}>
        <TouchableOpacity
          onPress={handleSubmit(onSubmit)}
          style={[
            tw`mt-7 px-20 py-2.5 shadow-md`,
            { backgroundColor: Colores.primary, borderRadius: 15 },
          ]}
        >
          <Text style={tw`text-white text-center`}>Siguiente</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
