import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { useMutation, useQueryClient } from "react-query";
import { useForm, Controller } from "react-hook-form";
import WebDatePicker from "../components/WebDatePicker";
import { useNavigation } from "@react-navigation/native";
import AsignarColaboradoresRoster from "../pages/roster/AsignarColaboradoresRoster";

import "react-datepicker/dist/react-datepicker.css";

import tw from "twrnc";
import { editarRoster, nuevoRoster } from "../services/apiClient";

import ResultadoModal from "../components/ResultadoModal";
import MensajeError from "../components/MensajeError";
import { Colores } from "../constants/colores";
import { Loader } from "../components/Loader";

export const RosterForm = (props) => {
  const roster = props.route.params?.roster;
  const queryClient = useQueryClient();
  const { width } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(false);
  const [responseError, setResponseError] = useState();
  const [fechaInicio, setfechaInicio] = useState(new Date());
  const [fechaFin, setfechaFin] = useState(new Date());
  const [dias, setdias] = useState(0);
  const valoresIniciales = {
    nombre: "",
    ubicacion: "",
    descripcion: "",
    observaciones: "",
    fecha_inicio: fechaInicio,
    fecha_fin: fechaFin,
  };
  const [initialValues, setInitialValues] = useState(valoresIniciales);
  const navigator = useNavigation();
  const [mostrarRosterColaboradorForm, setRosterColaboradorForm] =
    useState(false);

  useEffect(() => {
    if (roster) {
      setInitialValues({
        nombre: roster?.nombre,
        ubicacion: roster?.ubicacion,
        descripcion: roster?.descripcion,
        observaciones: roster?.observaciones,
        fecha_inicio: new Date(roster?.fecha_inicio),
        fecha_fin: new Date(roster?.fecha_fin),
      });

      // Call reset to set the form values when roster changes
      reset({
        nombre: roster?.nombre,
        ubicacion: roster?.ubicacion,
        descripcion: roster?.descripcion,
        observaciones: roster?.observaciones,
        fecha_inicio: new Date(roster?.fecha_inicio),
        fecha_fin: new Date(roster?.fecha_fin),
      });
    } else {
      reset(valoresIniciales);
    }
  }, [props, roster, reset]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    mode: "onBlur",
    defaultValues: initialValues,
  });

  const resetForm = () => {
    reset(valoresIniciales);
  };

  const cambiarVista = (roster) => {
    navigator.navigate("Asignar Colaboradores Roster", {
      roster,
    });
  };

  const nuevoRosterMutate = useMutation(
    nuevoRoster,
    {
      onSuccess: async (response) => {
        queryClient.invalidateQueries("roster");
        queryClient.invalidateQueries("proximosRosters");
        queryClient.invalidateQueries("RostersFinalizados");
        queryClient.invalidateQueries("colaboradoresParaRosterQuery");
        resetForm();
        setIsLoading(false);
        cambiarVista(response.data.roster);
      },
      onError: async (error) => {
        if (error.response.status === 403) {
          setmostrarAlert({
            show: true,
            customView: (
              <ResultadoModal
                success={false}
                message={error.response?.data?.message}
              />
            ),
          });
          return;
        }

        let mensajeErrores = "";
        for (const [key, value] of Object.entries(error.response.data.errors)) {
          mensajeErrores = mensajeErrores + " \n" + value;
        }
        setResponseError(mensajeErrores);
        setIsLoading(false);
      },
    },
    {
      retry: 0,
    }
  );

  const editarRosterMutate = useMutation(editarRoster, {
    onSuccess: async (response) => {
      queryClient.invalidateQueries("roster");
      queryClient.invalidateQueries("proximosRosters");
      queryClient.invalidateQueries("RostersFinalizados");
      queryClient.invalidateQueries("colaboradoresParaRosterQuery");
      resetForm();
      setIsLoading(false);
      cambiarVista(response.data);
    },
    onError: async (error) => {
      if (error.response.status === 403) {
        setmostrarAlert({
          show: true,
          customView: (
            <ResultadoModal
              success={false}
              message={error.response?.data?.message}
            />
          ),
        });
        return;
      }

      let mensajeErrores = "";
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        mensajeErrores = mensajeErrores + " \n" + value;
      }
      setResponseError(mensajeErrores);
      setIsLoading(false);
    },
  });

  const formData = new FormData();

  useEffect(() => {
    setdias(moment(fechaFin).diff(fechaInicio, "days"));
  }, [fechaInicio, fechaFin]);

  const onSubmit = (data) => {
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        if (data[key] && data[key] !== "") {
          formData.append(key, data[key]);
        }
      }
    }
    formData.set(
      "fecha_inicio",
      moment(data.fecha_inicio).format("YYYY/MM/DD")
    );
    formData.set("fecha_fin", moment(data.fecha_fin).format("YYYY/MM/DD"));

    //Si hay roster quiere decir que esta editando uno
    if (roster) {
      editarRosterMutate.mutate({ rosterId: roster.id, formData });
    } else {
      nuevoRosterMutate.mutate(formData);
    }

    setIsLoading(true);
    setResponseError();
  };

  return (
    <View
      style={
        width >= 768 ? tw`px-15 py-10 bg-white rounded-xl` : tw`px-4 bg-white`
      }
    >
      {width >= 768 && (
        <View
          style={[
            tw`w-full justify-center bg-white`,
            { borderTopLeftRadius: 20, borderTopRightRadius: 20 },
          ]}
        >
          <Text style={tw`text-lg font-semibold text-gray-600 mb-5`}>
            {roster ? "Edtiar Roster" : "Nuevo Roster"}
          </Text>
        </View>
      )}
      {/* VISTA NUEVA? */}

      {mostrarRosterColaboradorForm && (
        <AsignarColaboradoresRoster roster={persona.name} />
      )}

      {/* VISTA NUEVA? */}

      {/* primer input // NOMBRE */}
      <View style={tw`pl-5`}>
        <Text style={tw` mt-5 text-lg text-gray-900`}>Nombre</Text>
        <Controller
          control={control}
          rules={{
            required: "Indique un nombre",
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={[
                tw` mt-2 w-full px-3 py-2.5 text-gray-700 border`,
                { borderRadius: 50, borderColor: Colores.primary },
              ]}
              onBlur={onBlur}
              onChangeText={onChange}
              multiline={true}
              placeholder="Nombre"
              value={value}
            />
          )}
          name="nombre"
        />
        {errors?.nombre && <MensajeError message={errors.nombre?.message} />}
      </View>

      <View style={tw`pl-5`}>
        <Text style={tw` mt-5 text-lg text-gray-900`}>Ubicacion</Text>
        <Controller
          control={control}
          rules={{
            required: "Indique una ubicacion",
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={[
                tw` mt-2 w-full px-3 py-2.5 text-gray-700 border`,
                { borderRadius: 50, borderColor: Colores.primary },
              ]}
              onBlur={onBlur}
              onChangeText={onChange}
              multiline={true}
              placeholder="SAC, Jujuy"
              value={value}
            />
          )}
          name="ubicacion"
        />
        {errors?.ubicacion && (
          <MensajeError message={errors.ubicacion?.message} />
        )}
      </View>

      <View style={tw`pl-5`}>
        <Text style={tw` mt-5 text-lg text-gray-900`}>Observaciones</Text>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={[
                tw` mt-2 w-full px-3 py-2.5 text-gray-700 border`,
                { borderRadius: 50, borderColor: Colores.primary },
              ]}
              onBlur={onBlur}
              onChangeText={onChange}
              multiline={true}
              placeholder="Observaciones"
              value={value}
            />
          )}
          name="observaciones"
        />
        {errors?.roster && <MensajeError message={errors.roster?.message} />}
      </View>

      <View style={tw`pl-5`}>
        <Text style={tw` mt-5 text-lg text-gray-900`}>Fecha Inicio</Text>

        <Controller
          control={control}
          name="fecha_inicio"
          render={({ field: { value, onChange } }) => (
            <WebDatePicker
              selected={value}
              onChange={onChange}
              minDate={new Date()}
            />
          )}
          defaultValue={null}
          rules={{
            validate: (value) => {
              const fecha_fin = getValues("fecha_fin");
              if (value && fecha_fin && value >= fecha_fin) {
                return "Fecha de inicio debe ser menor que la fecha de fin.";
              }
              return true;
            },
          }}
        />

        {errors?.fecha_inicio && (
          <MensajeError message={errors.fecha_inicio?.message} />
        )}
      </View>

      <View style={tw`pl-5`}>
        <Text style={tw` mt-5 text-lg text-gray-900`}>Fecha fin</Text>
        <Controller
          control={control}
          name="fecha_fin"
          render={({ field: { value, onChange } }) => (
            <WebDatePicker
              selected={value}
              onChange={onChange}
              minDate={new Date()}
            />
          )}
          defaultValue={null}
          rules={{
            validate: (value) => {
              const fecha_inicio = getValues("fecha_inicio");
              if (value && fecha_inicio && value <= fecha_inicio) {
                return "Fecha de fin debe ser mayor que la fecha de inicio.";
              }
              return true;
            },
          }}
        />
        {errors?.fecha_fin && (
          <MensajeError message={errors.fecha_fin?.message} />
        )}
      </View>

      {responseError ? (
        <View
          style={tw`flex flex-col items-center justify-center bg-red-200 border border-red-500 rounded-lg p-2 my-4`}
        >
          <Text
            style={tw`flex flex-col items-center justify-center text-red-500 text-center`}
          >
            {responseError}
          </Text>
        </View>
      ) : null}

      <View style={tw`items-center justify-center mb-10`}>
        <TouchableOpacity
          enabled={!isLoading}
          onPress={handleSubmit(onSubmit)}
          style={[
            tw`mt-7 px-20 py-2.5 shadow-md`,
            { backgroundColor: Colores.primary, borderRadius: 15 },
          ]}
        >
          {isLoading ? (
            <Loader color="white" />
          ) : (
            <Text style={tw`text-white text-center`}>Siguiente</Text>
          )}
        </TouchableOpacity>
      </View>
    </View>
  );
};
