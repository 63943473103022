import axios from "axios";
import BASE_URL, { APIGATEWAY_URL } from "../constants/connection";

import { getItem } from "./asyncStore";

//comentar para implementar el apigateway
axios.defaults.baseURL = BASE_URL;

axios.interceptors.request.use(
  async (config) => {
    if (!config.headers.Authorization) {
      const token = await getItem("user").then((user) => {
        if (user != null && user != "null") {
          return JSON.parse(user).access_token;
        }
      });
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default axios;

/*descomentar para aplicar el api gateway
(async () => {
  await getItem("apiURL").then(apiURL => {
    const url = apiURL?.replaceAll('"','');
    axios.defaults.baseURL = url;
  });
})()
*/
export const login = async (data) => {
  /*descomentar para aplicar el api gateway
  //Setea el url de la api
  await getItem("apiURL").then(apiURL => {
    const url = apiURL?.replaceAll('"','');
    axios.defaults.baseURL = url;
  });
  */
  return await axios.post("user/login", data);
};

export const cambiarPasswordOlvidada = async (data) => {
  return await axios.post("user/olvido-password", data);
};

export const adminReseteaPassword = async (colaboradorId) => {
  return await axios.post(`user/admin-resetea-password/${colaboradorId}`);
};

export const enviarExpoPushToken = async (expoPushToken) => {
  return await axios.post(`user/expoPushToken`, expoPushToken, {
    "Content-Type": "application/json",
  });
};

export const confirmarReseteoPassword = async (token) => {
  return await axios.post(`user/resetear-password/${token}`);
};

//AVISOS
export const getAvisos = async (filtros) => {
  return await axios.get(`gestion-aviso/aviso?${filtros}`);
};

export const getAvisosInfiniteScroll = async ({ pageParam = 1 }) => {
  const response = await axios.get(
    `gestion-aviso/aviso?filter[esTemporal]=0&paginate=10&page=${pageParam}&include=megusta,megustaCount,autor,autor.puestoActual,autor.departamentoActual,autor.empresaActual,fotos,comentarios,comentariosCount`
  );
  return response.data;
};

export const getAvisosTemporales = async ({ filtros }) => {
  if (filtros) {
    return await axios.get(
      `gestion-aviso/aviso?filter[esTemporalActiva]=1&filter[esTemporal]=1&${filtros}`
    );
  }
  return await axios.get(
    `gestion-aviso/aviso?filter[esTemporalActiva]=1&filter[esTemporal]=1`
  );
};

export const getAvisosCompleto = async () => {
  return await axios.get(
    "gestion-aviso/aviso?include=visualizacionesCount,fotos"
  );
  return await axios.get(
    "gestion-aviso/aviso?include=visualizacionesCount,fotos"
  );
};

export const avisoVisto = async (avisoId) => {
  return await axios.post(`gestion-aviso/aviso/${avisoId}/visto`);
};

export const avisoMeGusta = async ({ avisoId, estado }) => {
  return await axios.post(`gestion-aviso/aviso/${avisoId}/megusta/${estado}`);
};

export const avisoComentarios = async (avisoId) => {
  return await axios.get(`gestion-aviso/aviso/${avisoId}?include=comentarios`);
};

export const nuevoComentarioAviso = async (data) => {
  return await axios.post("gestion-aviso/comentario", data);
};

export const nuevoAviso = async (formData) => {
  return await axios.post("gestion-aviso/aviso", formData, {
    headers: {
      "Content-Type": `multipart/form-data;`,
    },
    headers: {
      "Content-Type": `multipart/form-data;`,
    },
  });
};

export const editarAviso = async ({ avisoId, formData }) => {
  return await axios.post(`gestion-aviso/aviso/${avisoId}`, formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const eliminarAviso = async (avisoId) => {
  return await axios.delete(`gestion-aviso/aviso/${avisoId}`);
};

export const eliminarImagenAviso = async (id) => {
  return await axios.delete(`gestion-aviso/aviso/foto/${id}`);
};

//MENUS
export const menuSemanal = async () => {
  return await axios.post("gestion-menu/menu/semanal");
};

export const getComidas = async () => {
  return await axios.get("gestion-menu/comida");
};

export const getEstimativo = async () => {
  return await axios.get("gestion-menu/menu/verMenuSeleccionados/estimativo");
};

export const getGuarniciones = async () => {
  return await axios.get("gestion-menu/guarnicion");
};

export const getPostres = async () => {
  return await axios.get("gestion-menu/postre");
};

export const getTiposMenu = async () => {
  return await axios.get("gestion-menu/menu/verMenu/tipos");
};

export const nuevoMenu = async (formData) => {
  return await axios.post("gestion-menu/menu", formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const getEstimativoDiario = async () => {
  return await axios.get(
    `gestion-menu/menu/verMenuSeleccionados/estimativo/diario`
  );
};

export const exportarEstimativo = async (dias) => {
  return await axios.get(
    `gestion-menu/menu/verMenuSeleccionados/estimativo/exportar?dias=${dias}`,
    {
      responseType: "arraybuffer",
      headers: { "Content-Type": "blob" },
      responseType: "arraybuffer",
      headers: { "Content-Type": "blob" },
    }
  );
};

//colaborador elige el menu
export const elegirMenu = async (menuId) => {
  return await axios.post(`gestion-menu/menu/elegirMenu/${menuId}`);
};

export const verMenuSemanalSeleccionado = async () => {
  return await axios.get("gestion-menu/menu/verMenuSeleccionados/actual");
};

export const eliminarMenuSeleccionado = async (menuId) => {
  return await axios.delete(
    `gestion-menu/menu/eliminarMenuSeleccionado/${menuId}`
  );
};

const platoEndpoint = {
  comida: "comida",
  guarnicion: "guarnicion",
  postre: "postre",
};

export const nuevoPlato = async ({ tipoPlato, formData }) => {
  return await axios.post(
    `gestion-menu/${platoEndpoint[tipoPlato]}`,
    formData,
    {
      "Content-Type": "multipart/form-data",
    }
  );
};

export const editarPlato = async ({ tipoPlato, platoId, formData }) => {
  return await axios.post(
    `gestion-menu/${platoEndpoint[tipoPlato]}/${platoId}`,
    formData,
    {
      "Content-Type": "multipart/form-data",
    }
  );
};

export const getPlatos = async () => {
  return await axios.post("gestion-menu/menu/platos");
};

export const eliminarPlato = async ({ tipoPlato, idPlato }) => {
  return await axios.delete(
    `gestion-menu/${platoEndpoint[tipoPlato]}/${idPlato}`,
    {
      "Content-Type": "multipart/form-data",
    }
  );
};

//EMPRESAS
export const getEmpresas = async () => {
  return await axios.get("empresa");
};

export const getEmpresasDptosPuestos = async () => {
  return await axios.get(
    "empresa?include=departamentos,departamentos.puestos,departamentos.colaboradores,departamentosCount,departamentos.puestos.permisos,colaboradoresCount"
  );
};

export const getEmpresasDptos = async () => {
  return await axios.get("empresa?include=departamentos");
};

export const nuevaEmpresa = async (formData) => {
  return await axios.post("empresa", formData);
};

export const editarEmpresa = async ({ empresaId, formData }) => {
  return await axios.post(`empresa/${empresaId}`, formData, {
    "Content-Type": "application/json",
  });
};

export const eliminarEmpresa = async (empresaId) => {
  return await api.delete(`empresa/${empresaId}`);
};

//DEPARTAMENTOS
export const getDepartamentos = async (departamentoId) => {
  return await axios.get(
    "empresa?include=departamentos,departamentos.puestos,departamentos.colaboradores,departamentosCount,colaboradoresCount"
  );
};

export const nuevoDepartamento = async (formData) => {
  return await axios.post("departamento", formData);
};

export const editarDepartamento = async ({ departamentoId, formData }) => {
  return await axios.post(`departamento/${departamentoId}`, formData, {
    "Content-Type": "application/json",
  });
};

export const getDepartamento = async (departamentoId) => {
  return await axios.get(`departamento/${departamentoId}`);
};

//PUESTOS
export const getPuestoColaboradores = async (puestoId) => {
  return await axios.get(
    `puesto/${puestoId}/colaboradores?include=colaboradores.empresaActual,colaboradores.departamentoActual,colaboradores.puestoActual,permisos`
  );
};

export const nuevoPuesto = async (formData) => {
  return await axios.post("puesto", formData);
};

export const editarPuesto = async ({ puestoId, formData }) => {
  return await axios.post(`puesto/${puestoId}`, formData, {
    "Content-Type": "application/json",
  });
};

export const getPuesto = async (puestoId) => {
  return await axios.get(`puesto/${puestoId}`);
};

export const asignarPermisoPuesto = async ({ puestoId, data }) => {
  return await axios.post(`puesto/${puestoId}/asignarPermiso`, data, {
    "Content-Type": "application/json",
  });
};

//PERMISOS
export const getPermisos = async () => {
  return await axios.get(`permiso`);
};

export const getPermisosModulos = async () => {
  return await axios.get("permiso/lista");
};

//COLABORADORES

export const actualizarPassword = async ({ userId, formData }) => {
  console.log(formData);
  return await axios.post(`user/actualizar-password/${userId}`, formData);
};

export const getColaboradoresPublica = async (page = 1, busqueda = "") => {
  const response = await axios.get(
    `colaborador/lista/publica?paginate=10&page=${page}&filter[nombre]=${busqueda}`
  );
  return response.data;
};
export const getColaboradores = async () => {
  return await axios.get(
    "colaborador?include=user,departamentoActual,empresaActual,puestoActual,permisos,licenciaActiva"
  );
};

export const getColaboradoresEliminados = async () => {
  return await axios.get(
    "colaborador?include=user,departamentoActual,empresaActual,puestoActual,permisos&filter[trashed]=only"
  );
};

export const restaurarColaboradorEliminado = async (colaboradorId) => {
  return await axios.post(`colaborador/${colaboradorId}/restaurar`, {
    "Content-Type": "application/json",
  });
};

export const getColaborador = async (id) => {
  return await axios.get(`colaborador/${id}`);
};

export const eliminarColaborador = async (colaboradorId) => {
  return await axios.delete(`colaborador/${colaboradorId}`);
};

export const nuevoColaborador = async (formData) => {
  return await axios.post("colaborador", formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const editarColaborador = async ({ colaboradorId, formData }) => {
  return await axios.post(`colaborador/${colaboradorId}`, formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const asignarRelacionDependencia = async ({ colaboradorId, data }) => {
  return await axios.post(
    `colaborador/${colaboradorId}/asignar/empresa`,
    data,
    {
      "Content-Type": "application/json",
    }
  );
};

export const buscarColaboradores = async (filter) => {
  console.log("filter", filter);
  return await axios.get(
    `colaborador?include=departamentoActual,empresaActual,puestoActual&filter[any]=${filter}`
  );
};

//colaborador?filter[disponibles_para_roster]=true

//LICENCIAS
export const misLicencias = async (colaboradorId) => {
  return await axios.get(
    `licencia?&include=autorizante,opcion,opcion.tipo,estado&filter[colaborador_id]=${colaboradorId}`
  );
};

export const getMisLicencias = async () => {
  return await axios.get(`licencia/licencias/propias`);
};

export const getLicencias = async () => {
  return await axios.get(
    `licencia?&include=autorizante,solicitante,empresa,opcion,opcion.tipo,estado`
  );
};

export const getLicenciasTipos = async () => {
  return await axios.get(`tipo/licencia`);
};

export const getLicenciasEstados = async () => {
  return await axios.get(`estado/licencia`);
};

export const getLicenciasOpciones = async () => {
  return await axios.get(`opcion/licencia?include=tipo`);
};

export const eliminarLicencia = async (licenciaId) => {
  return await axios.delete(`licencia/${licenciaId}`);
};

export const nuevaLicencia = async (formData) => {
  return await axios.post("licencia", formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const actualizarCertificado = async ({ licenciaId, formData }) => {
  return await axios.post(`licencia/${licenciaId}/subirCertificado`, formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const autorizarLicencia = async ({ licenciaId, formData }) => {
  return await axios.post(`licencia/${licenciaId}/autorizar`, formData);
};

export const exportarLicencias = async (query = "") => {
  return await axios.get(`licencia/licencias/exportar?${query}`, {
    responseType: "arraybuffer",
    headers: { "Content-Type": "blob" },
  });
};

//BENEFICIOS
export const getBeneficiosFiltrados = async () => {
  return await axios.get("beneficio?filter[activo]=true");
};

export const getBeneficios = async () => {
  return await axios.get("beneficio");
};

export const nuevoBeneficio = async (formData) => {
  return await axios.post("beneficio", formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const eliminarBeneficio = async (beneficioId) => {
  return await axios.delete(`beneficio/${beneficioId}`);
};

export const editarBeneficio = async ({ beneficioId, formData }) => {
  return await axios.post(`beneficio/${beneficioId}`, formData, {
    "Content-Type": "multipart/form-data",
  });
};

//SUGERENCIA
export const getSugerenciaTipos = async () => {
  return await axios.get("tipo/sugerencia");
};

export const getSugerencias = async () => {
  return await axios.get("sugerencia?&include=tipo,empresa,colaborador");
};

export const nuevaSugerencia = async (formData) => {
  return await axios.post("sugerencia", formData);
};

export const eliminarSugerencia = async (sugerenciaId) => {
  return await axios.delete(`sugerencia/${sugerenciaId}`);
};

//RECIBOS

export const getRecibos = async () => {
  return await axios.get("recibos?include=colaborador,empresa,tipo");
};

export const getDescargaRecibo = async (reciboId) => {
  return await axios
    .get(`recibos/descargar/${reciboId}`, { responseType: "blob" })
    .then((response) => {
      window.open(URL.createObjectURL(response.data));
    });
};

export const getMisRecibos = async () => {
  return await axios.get("recibos/misRecibos");
};

export const getRazonesDesacuerdo = async () => {
  return await axios.get("recibos/razonesDesacuerdo");
};

export const getTiposRecibo = async () => {
  return await axios.get("recibos/tiposRecibo");
};

export const getConfiguracionFirmaRecibo = async (empresaId) => {
  return await axios.get(`recibos/firma/configuracion/${empresaId}`);
};

export const firmarRecibo = async ({ reciboId, data }) => {
  return await axios.post(`recibos/firmar/${reciboId}`, data);
};

export const cargarRecibos = async (formData) => {
  return await axios.post("recibos/enviar", formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const configurarFirmaRecibo = async ({ empresaId, formData }) => {
  return await axios.post(`recibos/firma/configurar/${empresaId}`, formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const getRecibosReportes = async (desde, hasta, firmado) => {
  return await axios.get(
    `recibos/reporte?desde=${desde}&hasta=${hasta}&firmado=${firmado}`
  );
};

export const eliminarRecibo = async (reciboId) => {
  return await axios.delete(`recibos/${reciboId}`);
};

//PLAN DE CARRERA
export const nuevaSolicitudPlanDeCarrera = async (formData) => {
  return await axios.post("carrera", formData);
};

export const getSolicitudesPlanDeCarrera = async () => {
  return await axios.get(
    "carrera?include=colaborador,empresa,departamento,puesto,colaborador.empresaActual,colaborador.departamentoActual,colaborador.puestoActual"
  );
};

// PRODE

export const getFixture = async () => {
  return await axios.get("/prode/fixture");
};

export const getMisPronosticos = async () => {
  return await axios.get("prode/misPronosticos");
};

export const getRanking = async () => {
  return await axios.get("/prode/ranking");
};

export const pronosticar = async ({ fixtureId, formData }) => {
  return await axios.post(`prode/pronosticar/${fixtureId}`, formData);
};

export const formValidarDatos = async ({ formData }) => {
  return await axios.post(`/prode/validarDatos`, formData);
};

//LOGS
export const getLogsLogin = async () => {
  return await axios.get("logs/acceso");
};

// NOTIFICACIONES

export const getNotificaciones = async () => {
  return await axios.get("notificaciones");
};

export const marcarNotificacionComoLeida = async (notificacionId) => {
  return await axios.post("notificaciones/marcarLeida", { id: notificacionId });
  return await axios.post("notificaciones/marcarLeida", { id: notificacionId });
};
export const marcarTodasNotificacionesLeidas = async () => {
  return await axios.post("notificaciones/marcarTodas");
};

// DASHBOARD

export const getInformacionDashboard = async () => {
  return await axios.get("licencia/licencias/reporte");
};

// MI PERFIL

export const subirDeclaracionJurada = async ({ formData }) => {
  return await axios.post("legajo/constancia/direccion", formData);
};

//API GATEWAY CODIGOS DE INVITACION

export const getCodigosDeInvitacion = async () => {
  return await axios.get(APIGATEWAY_URL);
};

export const getUrl = async (codigo) => {
  return await axios.get(`${APIGATEWAY_URL}${codigo}`);
};

export const crearCodigoDeInvitacion = async (data) => {
  return await axios.post(APIGATEWAY_URL, data, {
    "Content-Type": "application/json",
  });
};

export const eliminarCodigoInvitacion = async (codigo) => {
  return await axios.delete(`${APIGATEWAY_URL}${codigo}`);
};

//ITEMS DEL COLABORADOR
export const getTiposItem = async () => {
  return await axios.get("/items/tipos");
};

export const getItems = async (page) => {
  return await axios.get(
    `items?include=asigno,observaciones,asignado,archivos,tipo,observaciones.autor&paginate=10&page=${
      page + 1
    }`
  );
};

export const asignarItem = async (formData) => {
  return await axios.post("items", formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const editarItem = async ({ id, formData }) => {
  return await axios.post(`items/${id}`, formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const misItems = async () => {
  return await axios.get("items/misItems");
};

export const itesmColaborador = async (id) => {
  return await axios.get(`items/colaborador/${id}`);
};

export const eliminarItem = async (id) => {
  return await axios.delete(`items/${id}`);
};

export const aceptarEntregaItem = async (id) => {
  return await axios.post(`items/${id}/aceptarEntregaItem`);
};

export const rechazarEntregaItem = async (id) => {
  return await axios.post(`items/${id}/rechazarEntregaItem`);
};

export const aceptarDevolucionItem = async (id) => {
  return await axios.post(`items/${id}/aceptarDevolucionItem`);
};

export const rechazarDevolucionItem = async (id) => {
  return await axios.post(`items/${id}/aceptarEntregaItem`);
};

export const archivoItem = async (path) => {
  return await axios.get(`archivos?path=${path}`, {
    responseType: "arraybuffer",
    headers: { "Content-Type": "blob" },
  });
};

export const descargarArchivoItem = async (id) => {
  const response = await axios.get(`archivos/descargar/${id}`, {
    responseType: "blob",
  });

  const type = response.headers["content-type"];
  const ext = type.split("/")[1];
  console.log(response.headers);

  const fileName = `archivo-${id}.${ext}`;

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const eliminarArchivoItem = async (id) => {
  return await axios.delete(`archivos/${id}`);
};

//MODULOS
export const getModulos = async () => {
  return await axios.get("modulos");
};

// ROSTER

export const nuevoRoster = async (formData) => {
  return await axios.post("roster", formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const getRosterEnProceso = async () => {
  return await axios.get(
    "roster?filter[estado]=proceso&include=creador,colaboradores,registroLog,colaboradoresCount"
  );
};

export const getProximosRosters = async () => {
  return await axios.get(
    "roster?filter[proximos_rosters]=true&include=creador,colaboradores,registroLog,colaboradoresCount"
  );
};

export const getRostersFinalizados = async () => {
  return await axios.get(
    "roster?filter[estado]=finalizado&include=creador,colaboradores,registroLog,colaboradoresCount"
  );
};

export const editarRoster = async ({ rosterId, formData }) => {
  return await axios.post(`roster/${rosterId}`, formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const getColaboradoresParaRoster = async () => {
  return await axios.get("colaborador?filter[disponibles_para_roster]=true");
};
export const agregarColaboradorARoster = async ({ rosterId, data }) => {
  return await axios.post(`roster/${rosterId}/agregarColaborador`, data, {
    "Content-Type": "application/json",
  });
};

export const eliminarRoster = async (id) => {
  return await axios.delete(`roster/${id}`);
};

/* export const actualizarPassword = async ({userId, formData}) => {
  console.log(formData)
  return await axios.post(`user/actualizar-password/${userId}`, formData);
};

export const nuevoColaborador = async (formData) => {
  return await axios.post("colaborador", formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const getColaboradoresPublica = async (page = 1, busqueda = '' ) => {
  const response = await axios.get(`colaborador/lista/publica?paginate=10&page=${page}&filter[nombre]=${busqueda}`);
  return response.data;
};
export const getColaboradores = async () => {
  return await axios.get(
    "colaborador?include=user,departamentoActual,empresaActual,puestoActual,permisos,licenciaActiva"
  );
};

export const getColaboradoresEliminados = async () => {
  return await axios.get(
    "colaborador?include=user,departamentoActual,empresaActual,puestoActual,permisos&filter[trashed]=only"
  );
};

export const restaurarColaboradorEliminado = async (colaboradorId) => {
  return await axios.post(`colaborador/${colaboradorId}/restaurar`, {
    "Content-Type": "application/json",
  });
};

export const getColaborador = async (id) => {
  return await axios.get(`colaborador/${id}`);
};

export const eliminarColaborador = async (colaboradorId) => {
  return await axios.delete(`colaborador/${colaboradorId}`);
};



export const editarColaborador = async ({ colaboradorId, formData }) => {
  return await axios.post(`colaborador/${colaboradorId}`, formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const asignarRelacionDependencia = async ({ colaboradorId, data }) => {
  return await axios.post(`colaborador/${colaboradorId}/asignar/empresa`, data, {
    "Content-Type": "application/json",
  });
}; */
