import React from "react";
import { Text, View, TextInput, TouchableOpacity } from "react-native";
import { getUrl } from "../services/apiClient";
import { useAtom } from "jotai";
import { apiAtom, awesomeAlertAtom } from "../store/store";
import { useMutation } from "react-query";
import { Controller, useForm } from "react-hook-form";
import tw from "twrnc";
import { Colores } from "../constants/colores";
import { Loader } from "../components/Loader";
import MensajeError from "../components/MensajeError";

const EnviarCodigoInvitacionForm = () => {
  const [, setApiURL] = useAtom(apiAtom);
  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const enviarCodigoInvitacionMutate = useMutation(getUrl, {
    onSuccess: async (response) => {
      if (response.status === 200) {
        const apiUrl = response.data.invitationCode.apiUrl;
        setApiURL(apiUrl.replace("/front",""));
      }

      resetForm();
    },
    onError: async (error) => {
      console.log(error.response.data);
      let message = "Hubo un error";
      if (error?.response?.data?.message) {
        message = error.response.data.message;
        setError('code', { type: 'custom', message });
      }
    },
  });

 

  const resetForm = () => {
    reset({ code: "" });
  };

  const onSubmit = (data) => {
    const { code } = data;
    enviarCodigoInvitacionMutate.mutate(code);
  };
  return (
    <View style={tw`w-full flex flex-col items-start jsutify-center gap-4`}>
      <View style={tw`w-full flex flex-col`}>
        <Controller
          control={control}
          rules={{
            required: "Indique un código",
            minLength: {
              value: 3,
              message: "Al menos 3 caracteres",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={[
                tw`w-md px-3 py-3 text-gray-700 border border-slate-400 rounded-lg`,
              ]}
              onBlur={onBlur}
              onChangeText={onChange}
              multiline={true}
              placeholder="p. ej. ABC123"
              value={value}
            />
          )}
          name="code"
        />
        {errors?.code && <MensajeError message={errors.code?.message} />}
      </View>

      <View style={tw`w-full flex flex-row items-center`}>
        {enviarCodigoInvitacionMutate.isLoading &&
        !enviarCodigoInvitacionMutate.isError ? (
          <Loader />
        ) : (
          <TouchableOpacity
            onPress={handleSubmit(onSubmit)}
            style={[
              tw`w-full py-2 rounded-lg shadow-md`,
              { backgroundColor: Colores.primary },
            ]}
          >
            <Text style={tw`text-white text-center font-semibold`}>Ingresar</Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export default EnviarCodigoInvitacionForm;
