import { useAtom } from "jotai";
import React from "react";
import { View, Text, ScrollView, SafeAreaView, useWindowDimensions } from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import { PageContainer } from "../../components/PageContainer";
import { Colores } from "../../constants/colores";

import EmpresaForm from "../../forms/EmpresaForm";
import { awesomeAlertAtom } from "../../store/store";

export default function CrearEmpresaScreen(props) {
  const { navigation, empresa } = props.route.params;
  const [awsomeAlert, _] = useAtom(awesomeAlertAtom);
  const {width} = useWindowDimensions();
  return (
    <PageContainer paddingMd >
      <View style={[{ flex: 1, padding: 16,backgroundColor:'#FFF'} , width > 768 && { borderRadius: 20 }]}>
        <EmpresaForm navigation={navigation} empresa={empresa ?? {}} />
      </View>
      <AwesomeAlert
        show={awsomeAlert.show}
        showProgress={false}
        title={awsomeAlert.title}
        message={awsomeAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={awsomeAlert.showConfirmButton}
        confirmText="Aceptar"
        customView={awsomeAlert.customView}
        confirmButtonColor={Colores.success}
        onDismiss={awsomeAlert.onDismiss}
        onCancelPressed={awsomeAlert.onCancelPressed}
        onConfirmPressed={awsomeAlert.onConfirmPressed}
      />
    </PageContainer>
  );
}
