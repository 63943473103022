import React from "react";
import { View } from "react-native";
import tw from "twrnc";

import { PageContainer } from "../../components/PageContainer";
import { RosterColaboradorForm } from "../../forms/RosterColaboradorForm";

function AsignarColaboradoresRoster(props) {
  return (
    <PageContainer paddingMd>
      <View style={tw`w-full h-full`}>
        <RosterColaboradorForm {...props} />
      </View>
    </PageContainer>
  );
}

export default AsignarColaboradoresRoster;
