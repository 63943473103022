import React from "react";
import {
  View,
  SafeAreaView,
  useWindowDimensions,
  ScrollView,
} from "react-native";
import tw from "twrnc";

export const PageContainer = ({
  paddingXl,
  paddingLg,
  paddingMd,
  paddingSm,
  children,
  bgGray,
  ...rest
}) => {
  const { width } = useWindowDimensions();

  return (
    <>
      {paddingXl && width >= 768 && (
        <SafeAreaView style={{ flex: 1, backgroundColor: "FOF2F5" }}>
          <ScrollView>
            <View style={tw`flex-row mt-5`}>
              <View style={tw`flex-1`}></View>
              <View style={[tw`flex-2 h-full w-full`, { flexShrink: 1 }]}>
                {children}
              </View>
              <View style={tw`flex-1`}></View>
            </View>
          </ScrollView>
        </SafeAreaView>
      )}
      {paddingMd && width >= 768 && (
        <SafeAreaView style={{ flex: 1, backgroundColor: "FOF2F5" }}>
          <ScrollView>
            <View style={tw`flex-row mt-10`}>
              <View style={tw`flex-3`}></View>
              <View style={[tw`flex-6 h-full w-full`, { flexShrink: 1 }]}>
                {children}
              </View>
              <View style={tw`flex-3`}></View>
            </View>
          </ScrollView>
        </SafeAreaView>
      )}
      {paddingSm && width >= 768 && (
        <SafeAreaView style={{ flex: 1, backgroundColor: "FOF2F5" }}>
          <ScrollView>
            <View style={tw`flex-row mt-10`}>
              <View style={tw`flex-1`}></View>
              <View style={[tw`flex-8 h-full w-full`, { flexShrink: 1 }]}>
                {children}
              </View>
              <View style={tw`flex-1`}></View>
            </View>
          </ScrollView>
        </SafeAreaView>
      )}

      {!paddingXl && !paddingMd && !paddingSm && !paddingLg && width >= 768 && (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
          <View
            style={[
              width >= 768 ? tw`h-full w-full px-20` : tw`h-full w-full`,
              { flexShrink: 1 },
            ]}
          >
            {children}
          </View>
        </SafeAreaView>
      )}

      {width <= 768 && (
        <SafeAreaView
          style={{ flex: 1, backgroundColor: bgGray ? "#FOF2F5" : "#FFF" }}
        >
          <View
            style={[
              width >= 768 ? tw`h-full w-full px-20` : tw`h-full w-full`,
              { flexShrink: 1 },
            ]}
          >
            {children}
          </View>
        </SafeAreaView>
      )}
    </>
  );
};
