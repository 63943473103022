import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import SelectBox from "react-native-multi-selectbox";
import { useQuery } from "react-query";
import { xorBy } from "lodash";

import { getEmpresas } from "../services/apiClient";
import { useAtom } from "jotai";
import { empresasSeleccionadasAtom, idsEmpresasAtom } from "../store/store";
import { Loader } from "./Loader";
import { Colores } from "../constants/colores";

export const MultiSelectEmpresas = ({ modelo }) => {
  const [seleccionadas, setEmpresasSeleccionadas] = useState([]);
  const [_, setIdsEmpresas] = useAtom(idsEmpresasAtom);
  const { isLoading, isError, data, error } = useQuery(
    "empresas",
    getEmpresas,
    { cacheTime: 0 }
  );

  useEffect(() => {
    if (data && modelo) {
      console.log("empresas", data.data.empresas);
      //marcar empresas seleccionadas
      const empresasPreSeleccionadas = items.filter((item) =>
        modelo.empresas.includes(item.id)
      ); //vienen definidas en el modelo.empresas
      setEmpresasSeleccionadas(empresasPreSeleccionadas);
      setIdsEmpresas(modelo.empresas);
    }
  }, [data, modelo]);

  if (isLoading) return <Loader text="Cargando Empresas..." />;
  if (isError) {
    return <Text>No se pudieron cargar las empresas</Text>;
  }

  let items = [];
  items = data.data.empresas.map((empresa) => {
    return { id: empresa.id, item: empresa.nombre };
  });

  const onMultiChange = (item) => {
    console.log("item seleccionado", item);
    setEmpresasSeleccionadas(xorBy(seleccionadas, [item], "id"));
    setIdsEmpresas(
      xorBy(seleccionadas, [item], "id").map((empresa) => empresa.id)
    );
  };

  return (
    <View>
      <SelectBox
        colores={Colores}
        placeholder="Empresa (opcional)"
        label=""
        options={items}
        selectedValues={seleccionadas}
        onMultiSelect={onMultiChange}
        onTapClose={onMultiChange}
        isMulti
        hideInputFilter
        containerStyle={{
          display: "flex",
          // flexWrap: "wrap",
          gap: 16,
          overflow: "hidden",
        }}
        multiSelectInputFieldProps={{
          contentContainerStyle: {
            display: "flex",
            maxWidth: "100%",
            // flexWrap: "wrap",
            gap: 8,
          },
        }}
      />
    </View>
  );
};
