import React, { useEffect, useState } from "react";
import { FlatList, Image, StyleSheet, Text, View, TextInput, TouchableOpacity, Pressable, ScrollView } from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";
import { AntDesign } from '@expo/vector-icons';
import AwesomeAlert from "react-native-awesome-alerts";
import { useMutation, useQuery, useQueryClient } from "react-query";
import tw from 'twrnc';
import { Ionicons } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';

import { IconButton } from "../../components/IconButton";
import { Loader } from "../../components/Loader";
import { eliminarPlato, getPlatos } from "../../services/apiClient";
import DataTable from "../../components/DataTable/DataTable";
import Badge from "../../components/Badge";
import { PageContainer } from "../../components/PageContainer";
import { Colores } from "../../constants/colores";
import { Feather } from '@expo/vector-icons';
import { FloatingAction } from "react-native-floating-action";
import ModalFiltrarComida from "../../components/Menu/ModalFiltrarComidas";
import { FontAwesome } from '@expo/vector-icons';

export default function AdministrarPlatosScreen(props) {
  const [platoId, setplatoId] = useState();
  const [mostrarAlerta, setmostrarAlerta] = useState(false);
  const platos = useQuery("platos", getPlatos);
  const [filteredData, setFilteredData] = useState([])
  const [filtro, setFiltro] = useState("")
  const [modalFiltrarVisible, setModalFiltrarVisible] = useState(false)
  const [mostrarAlertEliminado, setmostrarAlertEliminado] = useState(false)
  const [buscador, setBuscador] = useState()
  const [buscadorActivo, setBuscadorActivo] = useState(false)
  const [viewData, setViewData] = useState([])


  const queryClient = useQueryClient();
  const eliminarMutate = useMutation(eliminarPlato, {
    onSuccess: async (response) => {
      console.log("plato eliminado", response.data);
      queryClient.invalidateQueries("platos");
      setmostrarAlerta(false);
    },
    onError: async (error) => {
      console.log("hubo un error al intentar eliminar");
    },
    onSettled: (data) => {
      if (data.data.ok) {
        setmostrarAlerta(false);
      }
    },
  });

  const filtrarComida = (comida, guarnicion, postre, activo, inactivo) => {
    let totalComidasFiltradas = []
    if (comida) {
      const resultadoComida = platos.data.data.platos.filter(item => item.tipo === "comida")
      if (activo) {
        const resultComida = resultadoComida.filter(item => item.habilitado === 1)
        totalComidasFiltradas = totalComidasFiltradas.concat(resultComida);
      } else if (inactivo) {
        const resultComida = resultadoComida.filter(item => item.habilitado === 0)
        totalComidasFiltradas = totalComidasFiltradas.concat(resultComida);
      } else {
        const resultComida = totalComidasFiltradas.concat(resultadoComida);
        totalComidasFiltradas = totalComidasFiltradas.concat(resultComida);
      }
    }
    if (guarnicion) {
      const resultadoGuarnicion = platos.data.data.platos.filter(item => item.tipo === "guarnicion")
      if (activo) {
        const resultGuarnicion = resultadoGuarnicion.filter(item => item.habilitado === 1)
        totalComidasFiltradas = totalComidasFiltradas.concat(resultGuarnicion);
      } else if (inactivo) {
        const resultGuarnicion = resultadoGuarnicion.filter(item => item.habilitado === 0)
        totalComidasFiltradas = totalComidasFiltradas.concat(resultGuarnicion);
      } else {
        const resultGuarnicion = totalComidasFiltradas.concat(resultadoGuarnicion);
        totalComidasFiltradas = totalComidasFiltradas.concat(resultGuarnicion);
      }
    }
    if (postre) {
      const resultadoPostre = platos.data.data.platos.filter(item => item.tipo === "postre")
      if (activo) {
        const resultPostre = resultadoPostre.filter(item => item.habilitado === 1)
        totalComidasFiltradas = totalComidasFiltradas.concat(resultPostre);
      } else if (inactivo) {
        const resultPostre = resultadoPostre.filter(item => item.habilitado === 0)
        totalComidasFiltradas = totalComidasFiltradas.concat(resultPostre);
      } else {
        const resultPostre = totalComidasFiltradas.concat(resultadoPostre);
        totalComidasFiltradas = totalComidasFiltradas.concat(resultPostre);
      }
    }
    if (activo && !comida && !guarnicion && !postre && !inactivo) {
      const resultActivo = platos.data.data.platos.filter(item => item.habilitado === 1)
      totalComidasFiltradas = resultActivo;
    }
    if (inactivo && !comida && !guarnicion && !postre && !activo) {
      const resultInactivo = platos.data.data.platos.filter(item => item.habilitado === 0)
      totalComidasFiltradas = resultInactivo;
    }
    if (!comida && !guarnicion && !postre && !activo && !inactivo) {
      totalComidasFiltradas = platos.data.data.platos
      setViewData(totalComidasFiltradas)
      setFilteredData(totalComidasFiltradas)
      setFiltro(false)
      setModalFiltrarVisible(false)
      return;
    }

    setViewData(totalComidasFiltradas)
    setFilteredData(totalComidasFiltradas)
    setModalFiltrarVisible(false)
    setFiltro(true)
  }

  const searchFilterFunction = (text) => {
    if (!text && !filtro) {
      setViewData(filteredData)
      setBuscadorActivo(false)
    }
    if (text && filtro) {
      const newData = filteredData.filter(item => {
        const itemData = item.nombre ? item.nombre.toUpperCase() : ''.toLocaleUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      })
      setViewData(newData)
      setBuscadorActivo(true)
    }
    if (text && !filtro) {
      const newData = platos?.data?.data?.platos.filter(item => {
        const itemData = item.nombre ? item.nombre.toUpperCase() : ''.toLocaleUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      })
      setViewData(newData)
      setBuscadorActivo(true)
    }
    if (text === "" && !filtro) {
      setViewData(filteredData)
      setBuscadorActivo(false)
    }
    if (text === "" && filtro) {
      setViewData(filteredData)
      setBuscadorActivo(false)
    }
  }

  useEffect(() => {
    searchFilterFunction(buscador);
  }, [buscador])

  const confirmar = (id) => {
    setplatoId(id);
    setmostrarAlerta(true);
  };

  const getPlato = (platoId) => {
    const [tipo, id] = platoId.split("-");
    const plato = platos.data.data.platos.filter(
      (plato) => plato.id == id && plato.tipo == tipo
    );
    if (plato.length) {
      //duevele el primer aviso que se econtro con ese id
      return plato[0];
    }
    console.log("No se encontro el plato para el id ", platoId);
    return;
  };

  if (platos.isLoading) return <Loader text="Cargando platos..." />;


  return (
    <>
      <PageContainer paddingMd>
        <ScrollView>
          <View
            style={[tw`p-5 bg-white`, { borderRadius: 20 }]}
          >
            <View style={tw`flex-row`}>
              <View style={tw`flex-1 justify-center`}>
                <Text style={tw`text-lg font-semibold text-gray-500`}>
                  Administrar platos
                </Text>
              </View>

              <View style={tw`flex-1 justify-center items-end`}>

                <TouchableOpacity onPress={() => props.navigation.navigate('Crear plato')} style={[tw`border border-gray-200 flex-row py-2`, { borderRadius: 10 }]}>
                  <View style={[tw`justify-center pl-7 pr-5 py-2`, { borderLeftWidth: 2, borderColor: '#FF9900' }]}>
                    <Text style={tw`text-gray-700 text-center`}>Nuevo plato</Text>
                  </View>
                  <View style={tw`justify-center`}>
                    <AntDesign name="plus" size={20} color="gray" style={tw`mr-5`} />
                  </View>
                </TouchableOpacity>
              </View>
            </View>

            <View style={tw`flex flex-row mt-3 mb-2`}>
              <View style={tw`flex-9 justify-center`}>
                <TextInput
                  style={[tw` shadow-md w-full px-3 py-2.5 text-gray-700 border border-solid border-gray-300`, { borderRadius: 23 }]}
                  placeholder="Buscar plato"
                  onChangeText={(text) => setBuscador(text)}
                  placeholderTextColor={'#7D7D7D'}
                />
                <AntDesign name="search1" size={20} color={'#7D7D7D'} style={tw`absolute right-4`} />
              </View>

              <View style={tw`items-center justify-center flex-1`}>
                <TouchableOpacity style={[tw`rounded-full p-2`, { backgroundColor: Colores.primaryLight }]} onPress={() => setModalFiltrarVisible(true)}>
                  <Ionicons name="filter" size={24} color={Colores.primary} />
                </TouchableOpacity>
              </View>
            </View>

            <FlatList
              data={filtro || buscadorActivo ? viewData : platos.data.data.platos}
              keyExtractor={(item) => `tipo-${item.tipo}-${item.id}`}
              renderItem={({ item }) =>
              (
                <View style={[item.habilitado === 1 ? tw`mt-2 flex-row shadow-lg border border-gray-300` : tw`mt-2 flex-row`, { borderRadius: 16, backgroundColor: item.habilitado === 1 ? "#FFF" : "#f3f3f3" }]}>
                  <View style={tw`p-2`}>
                    <Pressable
                      onPress={() => {
                        const plato = getPlato(`${item.tipo}-${item.id}`);
                        props.navigation.navigate("Editar Plato", { plato });
                      }}
                    >
                      {item.foto === "http://45.224.140.5/nubiteamV2/storage/menu/cubiertos.png" ? (
                        <View style={[tw`shadow-md items-center justify-center w-[6rem] h-[6rem]`, { borderRadius: 16 }]}>
                          <FontAwesome name="cutlery" size={40} color={Colores.primaryLight} />
                        </View>
                      ) : (
                        <Image style={[tw`w-[6rem] h-[6rem]`, { borderRadius: 16 }]} source={{ uri: item.foto }} />
                      )}
                    </Pressable>
                  </View>
                  <Pressable style={tw`flex-1`}
                    onPress={() => {
                      const plato = getPlato(`${item.tipo}-${item.id}`);
                      props.navigation.navigate("Editar Plato", { plato });
                    }}
                  >

                    <View style={tw`flex-row mt-2`}>

                      <View style={tw`flex-9`}>
                        <View style={tw``}>
                          <Text style={tw` font-semibold mt-1`}>{item.nombre.charAt(0).toUpperCase() + item.nombre.slice(1).toLowerCase()}</Text>
                        </View>
                      </View>
                      <View style={tw`flex-1`}>
                        <Menu renderer={renderers.NotAnimatedContextMenu}>
                          <MenuTrigger>
                            <FontAwesome5 name="ellipsis-h" size={17} color={Colores.primary} />
                          </MenuTrigger>
                          <MenuOptions style={tw`p-1`} optionsContainerStyle={{ borderRadius: 10, marginTop: 20 }}>
                            <MenuOption
                              onSelect={() => {
                                const plato = getPlato(`${item.tipo}-${item.id}`);
                                props.navigation.navigate("Editar Plato", { plato });
                              }}
                              style={tw`flex flex-row px-2 py-2`}
                            >
                              <AntDesign name="edit" size={17} style={{ color: Colores.primary }} />
                              <Text style={[tw`ml-5`, { color: Colores.primary }]}>Editar</Text>
                            </MenuOption>
                            <MenuOption onSelect={() => confirmar(`${item.tipo}-${item.id}`)} style={tw`flex flex-row px-2 py-2`}>
                              <Feather name="trash" size={17} style={{ color: Colores.primary }} />
                              <Text style={[tw`ml-5`, { color: Colores.primary }]}>Eliminar</Text>
                            </MenuOption>
                          </MenuOptions>
                        </Menu>
                      </View>
                    </View>
                    <Text style={[tw`capitalize mt-2`, { color: '#7D7D7D' }]}>{item.tipo}</Text>
                    <Text style={[tw`mt-2`, { color: '#7D7D7D' }]}>Estado:  {item.habilitado === 1 ? (<Text style={{ color: '#02B879' }}>Activo</Text>) : (<Text style={{ color: '#B3261E' }}>Inactivo</Text>)}</Text>
                  </Pressable>
                  {/* </Pressable> */}

                </View>

              )}
            />


          </View>
        </ScrollView>
        <ModalFiltrarComida filtrarComida={filtrarComida} modalVisible={modalFiltrarVisible} setModalVisible={setModalFiltrarVisible} />
      </PageContainer>
      <AwesomeAlert
        show={mostrarAlerta}
        //showProgress={eliminarMutate.isLoading}
        title="Atención"
        message="Esta seguro que desea eliminar?"
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="Cancelar"
        confirmText="Eliminar"
        confirmButtonColor={Colores.danger}
        onCancelPressed={() => {
          setmostrarAlerta(false);
        }}
        onConfirmPressed={() => {
          setmostrarAlerta(false)
          const plato = getPlato(platoId);
          eliminarMutate.mutate({ tipoPlato: plato.tipo, idPlato: plato.id });
          setmostrarAlertEliminado(true)
        }}
      />
      <AwesomeAlert
        show={mostrarAlertEliminado}
        //showProgress={eliminarMutate.isLoading}
        title="Listo"
        message="Comida elimiminada"
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        onDismiss={() => setmostrarAlertEliminado(false)}
      />
    </>
  );
}