import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  Pressable,
  Modal,
} from "react-native";
import React, { useState, useEffect } from "react";
import tw from "twrnc";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from "react-native-popup-menu";
import { FontAwesome5 } from "@expo/vector-icons";
import { EvilIcons } from "@expo/vector-icons";
import { Colores } from "../../constants/colores";
import ColaboradorAvatar from "../Colaboradores/ColaboradorAvatar";
import { Loader } from "../Loader";
import RosterProcesoPreviewCard from "./RosterProcesoPreviewCard";

const estadosRosters = {
  proceso: "No hay rosters en proceso actualmente.",
  proximo:
    "Programa los próximos turnos con anticipación. Define las fechas de inicio y finalizacion para una planificación efectiva.",
  finalizado:
    "No hay roster finalizado para mostrar. Una vez que un roster haya completado su periodo de vigencia aparecerá en esta sección.",
};

const RosterProcesoCard = ({ rosters, children, query, tipo }) => {
  const { isLoading, refetch, isError } = query;
  const [modalVisible, setModalVisible] = useState(false);
  let fecha_inicio = new Date();
  let fecha_fin = (fecha) => {
    new Date(fecha);
  };

  const TabsModal = ({ visible, onClose }) => {};
  const [activeTab, setActiveTab] = useState("Tab1");
  const [selectedItem, setSelectedItem] = useState(null);

  const changeTab = (tab) => {
    setActiveTab(tab);
  };
  const abrirModal = (item) => {
    setSelectedItem(item);
    fecha_fin(item.fecha_fin);
    setModalVisible(true);
  };

  const cerrarModal = () => {
    setModalVisible(false);
  };
  const renderItem = ({ item }) => (
    <View style={tw`flex-row items-center justify-between my-1`}>
      <View
        style={tw`flex gap-2 flex-row items-center bg-white p-2 border border-gray-200 rounded-[10px]`}
      >
        <ColaboradorAvatar size={32} foto={item.foto} key={item.id} />
        <Text>
          {item.nombre} {item.apellido}
        </Text>
      </View>
    </View>
  );

  const renderItemLog = ({ item }) => {
    const options = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    let fecha_log = new Date(item.created_at).toLocaleDateString(
      undefined,
      options
    );
    return (
      <View
        style={tw`flex flex-col bg-white border border-gray-200 rounded-lg shadow-md my-2`}
      >
        <View
          style={tw`flex flex-row justify-between p-4 bg-gray-200 rounded-t-lg`}
        >
          <Text style={tw`text-lg font-semibold`}>
            {fecha_log.charAt(0).toUpperCase() + fecha_log.slice(1)}
          </Text>
        </View>
        <Text style={tw`mx-2 my-2 text-sm`}>{item.detalle}</Text>
      </View>
    );
  };

  return (
    <View
      style={tw`flex flex-col bg-white border-2 border-gray-200 rounded-lg p-4 gap-4`}
    >
      {/* MODAL*/}
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={cerrarModal}
      >
        <View
          style={tw`flex-1 justify-center items-center bg-opacity-50 bg-black`}
        >
          <View style={tw`bg-white p-5 rounded-md w-1/2`}>
            {/* header */}
            <View style={tw`flex flex-row`}>
              <Text style={tw`text-lg font-semibold text-gray-600`}>
                Roster
              </Text>
              <Text
                style={[
                  tw`ml-5 py-1 px-2`,
                  {
                    color: "#fff",
                    backgroundColor: "#02B879",
                    borderRadius: 85,
                  },
                ]}
              >
                {selectedItem?.estado ?? "Sin estado"}
              </Text>
            </View>
            <Text style={tw`text-xl font-semibold`}>
              {selectedItem?.nombre}
            </Text>

            <Text style={tw`text-sm font-semibold text-gray-600`}>
              <EvilIcons
                name="location"
                size={20}
                style={[tw`mr-1`, { color: Colores.primary }]}
              />
              {selectedItem?.ubicacion}
            </Text>
            {/* header */}
            {/* Encabezado de pestañas */}
            <View
              style={tw`flex-row justify-around mb-2 divide-y divide-blue-200`}
            >
              <Pressable
                onPress={() => changeTab("Tab1")}
                style={[
                  tw`p-2`,
                  activeTab === "Tab1" && tw`border-b-2 border-blue-500`,
                ]}
              >
                <Text style={tw`text-base font-semibold text-gray-700`}>
                  Detalle
                </Text>
              </Pressable>
              <Pressable
                onPress={() => changeTab("Tab2")}
                style={[
                  tw`p-2`,
                  activeTab === "Tab2" && tw`border-b-2 border-blue-500`,
                ]}
              >
                <Text style={tw`text-base font-semibold text-gray-700`}>
                  Historial
                </Text>
              </Pressable>
            </View>
            {/* Encabezado de pestañas */}

            {/* Contenido de las pestañas */}
            {activeTab === "Tab1" && (
              <View style={tw``}>
                <View style={tw`flex flex-row items-center gap-2`}>
                  <Text style={tw`text-sm text-[${Colores.primary}]`}>
                    Inicio:{" "}
                  </Text>
                  <Text style={tw`text-sm text-gray-700`}>
                    {selectedItem?.fecha_inicio}
                  </Text>
                  <Text style={tw`text-sm text-[${Colores.primary}]`}>
                    Fin:{" "}
                  </Text>
                  <Text style={tw`text-sm text-gray-700`}>
                    {selectedItem?.fecha_fin}
                  </Text>
                </View>

                <View style={tw`flex flex-row items-center gap-4`}>
                  <FontAwesome5
                    name="calendar"
                    size={20}
                    color={Colores.primary}
                  />
                  <Text style={tw`text-sm text-gray-700`}>
                    {selectedItem?.dias} días
                  </Text>
                </View>

                <View style={tw`flex flex-row items-center gap-4`}>
                  <FontAwesome5 name="user" size={20} color={Colores.primary} />
                  <Text style={tw`text-sm text-gray-700`}>
                    Creador: {selectedItem?.creador?.nombre}{" "}
                    {selectedItem?.creador?.apellido}
                  </Text>
                </View>

                <View style={tw`pb-5 flex flex-row items-center gap-4`}>
                  <FontAwesome5
                    name="users"
                    size={20}
                    color={Colores.primary}
                  />
                  <Text style={tw`text-sm  text-gray-700`}>
                    Colaboradores: {selectedItem?.colaboradores_count ?? "-"}
                  </Text>
                </View>

                {/* LISTA COLABORADORES */}
                <FlatList
                  data={selectedItem?.colaboradores}
                  keyExtractor={(item) => item.id}
                  renderItem={renderItem}
                />

                {/* LISTA COLABORADORES */}
              </View>
            )}
            {activeTab === "Tab2" && (
              <View>
                {/* ROSTER LOG */}
                <FlatList
                  style={[tw`mb-8`, { maxHeight: 500 }]}
                  data={selectedItem?.registro_log}
                  keyExtractor={(item) => item.id}
                  renderItem={renderItemLog}
                />
                {/* ROSTER LOG */}
              </View>
            )}
            {/* Contenido de las pestañas */}

            {/* Botón para cerrar el modal */}
            <View style={tw`items-end`}>
              <TouchableOpacity
                style={[
                  tw`px-5 py-3 flex-row justify-center mt-4 self-end}`,
                  { backgroundColor: "#F3F3F3", borderRadius: 50 },
                ]}
                onPress={cerrarModal}
              >
                <Text>Cerrar</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      {/* MODAL*/}

      {children}
      {isError ? (
        <TouchableOpacity
          onPress={refetch}
          style={tw`bg-[${Colores.primary} p-4 rounded-lg text-white]`}
        >
          Recargar
        </TouchableOpacity>
      ) : null}
      {!isLoading && !rosters.length ? (
        <View style={tw`bg-gray-200 p-4 rounded-lg`}>
          <Text style={tw`text-gray-500`}>{estadosRosters[tipo]}</Text>
        </View>
      ) : null}
      {isLoading ? (
        <Loader text="Cargando  rosters" />
      ) : (
        <FlatList
          style={[tw`bg-white mb-8`]} // ajusta este valor según tu necesidad
          contentContainerStyle={{ alignItems: "center" }}
          data={rosters}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => (
            <Pressable onPress={() => abrirModal(item)}>
              <RosterProcesoPreviewCard roster={item} />
            </Pressable>
          )}
        />
      )}
    </View>
  );
};

export default RosterProcesoCard;
