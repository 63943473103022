import React, { useState } from "react";
import {
  Image,
  Text,
  View,
  TouchableOpacity,
  FlatList,
  TextInput,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FontAwesome5 } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import AwesomeAlert from "react-native-awesome-alerts";
import tw from "twrnc";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from "react-native-popup-menu";

import { Loader } from "../../components/Loader";
import { eliminarAviso, getAvisosCompleto } from "../../services/apiClient";
import { PageContainer } from "../../components/PageContainer";
import moment from "moment";
import { Colores } from "../../constants/colores";
import { AntDesign } from "@expo/vector-icons";
import CrearAvisoModalDesktop from "../../components/Avisos/CrearAvisoModalDesktop";
import CrearHistoriaModalDesktop from "../../components/Avisos/CrearHistoriaModalDesktop";
import { Ionicons } from "@expo/vector-icons";
import { FloatingAction } from "react-native-floating-action";
import { useNavigation } from "@react-navigation/native";
import RadioButtonsEmpresas from "../../components/RadioButtonsEmpresas";

export default function AdministrarAvisosScreen(props, setfiltros, filtros) {
  const [filteredData, setFilteredData] = useState(null);
  const { width } = useWindowDimensions()
  const navigator = useNavigation();
  const [filtro, setFiltro] = useState("");
  const [empresaDefault, setEmpresaDefault] = useState();
  const { isLoading, isError, data, error } = useQuery(
    "avisosCompletos",
    getAvisosCompleto,
    { refetchOnWindowFocus: true }
  );
  const [abrirModalCrearAviso, setAbrirModalCrearAviso] = useState(false);
  const [abrirModalCrearHistoria, setAbrirModalCrearHistoria] = useState(false);
  const [mostrarAlertaEliminarAviso, setMostrarAlertaEliminarAviso] =
    useState(false);
  const [mostrarAlertaAvisoEliminado, setMostrarAlertaAvisoEliminado] =
    useState(false);
  const [avisoId, setAvisoId] = useState();
  const [estado, setestado] = useState(0);
  const queryClient = useQueryClient();

  const eliminarMutate = useMutation(eliminarAviso, {
    onSuccess: async (response) => {
      setMostrarAlertaEliminarAviso(false);
      queryClient.invalidateQueries("avisosCompletos");
      setMostrarAlertaAvisoEliminado(true);
    },
    onError: async (error) => {
      console.log("hubo un error al intentar eliminar");
    },
  });

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = avisos.filter((item) => {
        const itemData = item.titulo
          ? item.titulo.toUpperCase()
          : "".toLocaleUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setFilteredData(newData);
    } else {
      setFilteredData(avisos);
    }
  };

  const actions = [
    {
      text: "Nueva publicación",
      icon: <FontAwesome5 name="th" size={20} color="white" />,
      name: "Crear Aviso",
      position: 2,
    },
    {
      text: "Nueva historia",
      icon: <FontAwesome5 name="history" size={20} color="white" />,
      name: "Crear Historia",
      position: 1,
    },
  ];

  if (isLoading) {
    return <Loader text="Cargando avisos" />;
  }

  if (isError) {
    return <Text>Error: {error.message}</Text>;
  }

  const getAviso = (avisoId) => {
    const aviso = avisos.filter((aviso) => aviso.id === avisoId);
    if (aviso.length) {
      //duevele el primer aviso que se econtro con ese id
      return aviso[0];
    }
    console.log("No se encontro el aviso para el id ", avisoId);
    return;
  };

  const confirmar = (avisoId) => {
    setAvisoId(avisoId);
    setMostrarAlertaEliminarAviso(true);
  };

  //se cargan los avisos
  let avisos = null;
  if (data && data.data?.ok) {
    avisos = data.data.avisos;
  }

  const filtrarPorEmpresa = (empresaId) => {
    if (filteredData?.length) {
      const filtrado = avisos.filter((aviso) =>
        aviso.empresas.includes(empresaId)
      );
      setFilteredData(filtrado);
    } else {
      const filtrado = avisos.filter((aviso) =>
        aviso.empresas.includes(empresaId)
      );
      setFilteredData(filtrado);
    }
  };

  const limpiarFiltros = () => {
    searchFilterFunction("");
    setFiltro("");
    setFilteredData(null);
    setEmpresaDefault(null);
  };

  // console.log("No se encontro el aviso para el id ", data.data.avisos); /* despues de aviso viene los ID->empresas->IDs */

  return (
    <>
      <PageContainer paddingMd>
        <View style={tw`p-5 bg-white rounded-xl h-full`}>
          <ScrollView>
            <Text style={tw`text-lg font-semibold mb-5 text-gray-500`}>
              Administrar publicaciones
            </Text>

            {width >= 768 &&
              <View style={tw`flex-row mb-5`}>
                <View style={tw`flex-1 items-center`}>
                  <TouchableOpacity
                    onPress={() => setAbrirModalCrearAviso("Crear Aviso")}
                    style={[
                      tw`border border-gray-200 flex-row py-2`,
                      { borderRadius: 10 },
                    ]}
                  >
                    <View
                      style={[
                        tw`justify-center pl-7 pr-5 py-2`,
                        { borderLeftWidth: 2, borderColor: "blue" },
                      ]}
                    >
                      <Text style={tw`text-gray-700 text-center`}>
                        Nueva Publicación
                      </Text>
                    </View>
                    <View style={tw`justify-center`}>
                      <FontAwesome5
                        name="edit"
                        size={20}
                        color="gray"
                        style={tw`mr-5`}
                      />
                    </View>
                  </TouchableOpacity>
                </View>

                <View style={tw`flex-1 items-center`}>
                  <TouchableOpacity
                    onPress={() => setAbrirModalCrearHistoria("Crear Historia")}
                    style={[
                      tw`border border-gray-200 flex-row py-2`,
                      { borderRadius: 10 },
                    ]}
                  >
                    <View
                      style={[
                        tw`justify-center pl-7 pr-5 py-2`,
                        { borderLeftWidth: 2, borderColor: "red" },
                      ]}
                    >
                      <Text style={tw`text-gray-700 text-center`}>
                        Nueva Historia
                      </Text>
                    </View>
                    <View style={tw`justify-center`}>
                      <Ionicons
                        name="book-outline"
                        size={24}
                        color="gray"
                        style={tw`mr-5`}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            }
            {filteredData ? (
              <View style={tw`my-5 w-1/4 self-end`}>
                <TouchableOpacity
                  style={[
                    tw`py-2 px-5 ml-1 flex items-center justify-center`,
                    {
                      backgroundColor: Colores.primary,
                      borderRadius: 16,
                    },
                  ]}
                  onPress={limpiarFiltros}
                >
                  <MaterialIcons
                    name="cleaning-services"
                    size={20}
                    color="white"
                  />
                  <Text style={tw`text-white`}>Limpiar filtros</Text>
                </TouchableOpacity>
              </View>
            ) : null}

            <View style={tw`justify-center`}>
              <TextInput
                style={[
                  tw` w-full px-3 py-2 text-base font-normal text-gray-700 bg-white border border-solid border-gray-300 shadow-sm`,
                  { borderRadius: 23 },
                ]}
                placeholder="Buscar aviso"
                onChangeText={(text) => {
                  searchFilterFunction(text);
                  setFiltro(text);
                }}
                value={filtro}
              />

              <AntDesign
                name="search1"
                size={20}
                color={"#7D7D7D"}
                style={tw`absolute right-4`}
              />
            </View>

            <View style={tw`justify-center`}>
              <Text style={[tw`text-lg mt-3 mb-1`, { color: "#6c7581" }]}>
                Empresas
              </Text>
              <RadioButtonsEmpresas onSelect={filtrarPorEmpresa} defaultValue={empresaDefault} />
            </View>

            {filteredData && !filteredData?.length ? (
              <Text style={tw`text-center my-2`}>No hay resultados</Text>
            ) : null}

            <FlatList
              data={filteredData ? filteredData : avisos}
              keyExtractor={(item) => item.id}
              renderItem={({ item }) => (
                <View
                  style={[
                    tw`flex flex-row mt-2 border border-gray-300 shadow-md`,
                    { borderRadius: 16 },
                  ]}
                >
                  <View style={tw``}>
                    {item?.fotos ? (
                      <Image
                        style={[
                          tw`w-25 h-25`,
                          {
                            borderTopLeftRadius: 16,
                            borderBottomLeftRadius: 16,
                            borderBottomRightRadius: 16,
                          },
                        ]}
                        source={{ uri: item?.fotos[0]?.foto }}
                      />
                    ) : (
                      <View
                        style={[
                          tw`w-25 h-25 items-center justify-center`,
                          {
                            borderTopLeftRadius: 16,
                            borderBottomLeftRadius: 16,
                            borderBottomRightRadius: 16,
                            backgroundColor: Colores.primaryLight,
                          },
                        ]}
                      >
                        <MaterialIcons
                          name="image-not-supported"
                          size={30}
                          color={Colores.primary}
                        />
                      </View>
                    )}
                  </View>

                  <View style={tw`p-3 flex-1`}>
                    <Text style={tw`font-semibold text-lg`}>
                      {item.titulo === "undefined" ? "Sin titulo" : item.titulo}
                    </Text>
                    <Text style={tw`text-gray-500`}>
                      {moment(item.created_at).format("DD/MM/YYYY")}
                    </Text>
                    <Text style={tw`font-semibold text-sm text-slate-400`}>
                      {item.esTemporal === 1 ? "Historia" : "Publicación"}
                    </Text>
                  </View>

                  <View
                    style={[
                      tw`items-center justify-center px-2`,
                      {
                        backgroundColor: Colores.primary,
                        borderBottomRightRadius: 16,
                        borderTopRightRadius: 16,
                      },
                    ]}
                  >
                    <Menu renderer={renderers.NotAnimatedContextMenu}>
                      <MenuTrigger>
                        <FontAwesome5
                          name="ellipsis-v"
                          size={17}
                          color="#FFF"
                        />
                      </MenuTrigger>
                      <MenuOptions
                        style={tw`p-1`}
                        optionsContainerStyle={{
                          borderRadius: 10,
                          marginTop: 20,
                        }}
                      >
                        <MenuOption
                          onSelect={() => {
                            props.navigation.navigate("Editar Aviso", {
                              aviso: item,
                            });
                          }}
                          style={tw`flex flex-row px-2 py-2`}
                        >
                          <FontAwesome5
                            name="edit"
                            size={17}
                            style={{ color: Colores.primary }}
                          />
                          <Text style={[tw`ml-5`, { color: Colores.primary }]}>
                            Editar
                          </Text>
                        </MenuOption>
                        <MenuOption
                          onSelect={() => confirmar(item.id)}
                          style={tw`flex flex-row px-2 py-2`}
                        >
                          <FontAwesome5
                            name="trash"
                            size={17}
                            style={{ color: Colores.primary }}
                          />
                          <Text style={[tw`ml-5`, { color: Colores.primary }]}>
                            Eliminar
                          </Text>
                        </MenuOption>
                      </MenuOptions>
                    </Menu>
                  </View>
                </View>
              )}
            />
          </ScrollView>
        </View>
        <CrearAvisoModalDesktop
          modalVisibleAvisoDesktop={abrirModalCrearAviso}
          setModalVisibleAvisoDesktop={setAbrirModalCrearAviso}
          navigation={props.navigation}
        />
        <CrearHistoriaModalDesktop
          modalVisibleHistoriaDesktop={abrirModalCrearHistoria}
          setModalVisibleHistoriaDesktop={setAbrirModalCrearHistoria}
          navigation={props.navigation}
        />
      </PageContainer>
      <AwesomeAlert
          show={mostrarAlertaEliminarAviso}
          showProgress={eliminarMutate.isLoading}
          title="Atención"
          message="Esta seguro que desea eliminar?"
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="Cancelar"
          confirmText="Eliminar"
          confirmButtonColor={Colores.danger}
          onCancelPressed={() => {
            setMostrarAlertaEliminarAviso(false);
          }}
          onConfirmPressed={() => {
            eliminarMutate.mutate(avisoId);
          }}
        />    
        
        <AwesomeAlert
          show={mostrarAlertaAvisoEliminado}
          title="Listo"
          message="Se elimino la publicación correctamente"
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          onDismiss={() => {
            setMostrarAlertaAvisoEliminado(false);
          }}
        />

      {width < 768 &&
        <FloatingAction
          actions={actions}
          onPressItem={name => {
            // console.log(`selected button: ${name}`);
            navigator.navigate(`${name}`);
          }}
          style={{ position: 'absolute' }}
          floatingIcon={<AntDesign name="plus" size={24} color="white" />}
          // onPressMain={() => navigator.navigate('Solicitar Licencia')}
          showBackground={false}
        />
      }
    </>
  );
}
