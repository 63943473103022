import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
} from "react-native";
import { FontAwesome, MaterialCommunityIcons, SimpleLineIcons } from "@expo/vector-icons";

import { useQuery } from "react-query";
import tw from "twrnc";

import {
  getRosterEnProceso,
  getProximosRosters,
  getRostersFinalizados,
} from "../../services/apiClient";

import { awesomeAlertAtom } from "../../store/store";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import AwesomeAlert from "react-native-awesome-alerts";
import { PageContainer } from "../../components/PageContainer";
import { Colores } from "../../constants/colores";
import { useNavigation } from "@react-navigation/native";
import { Entypo } from "@expo/vector-icons";
import { Loader } from "../../components/Loader";
import RosterProcesoCard from "../../components/Rosters/RosterProcesoCard";

export default function AdministrarRosterScreen(props) {
  const rosterQuery = useQuery("roster", getRosterEnProceso);
  const proximosRostersQuery = useQuery("proximosRosters", getProximosRosters);
  const RostersFinalizadosQuery = useQuery(
    "RostersFinalizados",
    getRostersFinalizados
  );
  const navigator = useNavigation();
  const [awesomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);

  //traemos los rosters OK
  let rosters = [];

  if (rosterQuery.data && rosterQuery.data.data?.ok) {
    rosters = rosterQuery.data.data.rosters;
  }

  let proximosRosters = [];
  if (proximosRostersQuery.data && proximosRostersQuery.data.data?.ok) {
    proximosRosters = proximosRostersQuery.data.data.rosters;
  }

  let RostersFinalizados = [];
  if (RostersFinalizadosQuery.data && RostersFinalizadosQuery.data.data?.ok) {
    RostersFinalizados = RostersFinalizadosQuery.data.data.rosters;
  }

  if (
    rosterQuery.isLoading &&
    proximosRosters.isLoading &&
    RostersFinalizados.isLoading
  )
    return <Loader text="Cargando Rosters..." />;

  const getRoster = (rosterId) => {
    const roster = rosters.filter((roster) => roster.id === rosterId);
    if (roster.length) {
      return roster[0];
    }
    console.log("No se encontro el beneficio para el id ", rosterId);
    return;
  };

  return (
    <>
      <PageContainer paddingMd>
        <View style={tw`flex-1 mb-2 justify-center items-end`}>
          <TouchableOpacity
            onPress={() => props.navigation.navigate("Nuevo Roster")}
            style={[
              tw`self-end bg-white max-w-[220px] flex flex-row items-center shadow-md rounded-lg mr-2 my-4`,
            ]}
          >
            <View
              style={[
                tw`w-1 h-5 rounded-r-md`,
                { backgroundColor: Colores.primary },
              ]}
            ></View>
            <View
              style={tw`flex-1 flex flex-row items-center justify-center px-2 py-4`}
            >
              <Text style={tw`text-gray-700 text-center font-semibold`}>
                Nuevo Roster
              </Text>
              <View style={tw`min-w-16 items-end`}>
                <SimpleLineIcons
                  name="arrow-right"
                  size={16}
                  color={Colores.primary}
                />
              </View>
            </View>
          </TouchableOpacity>
        </View>
        <View style={tw`flex flex-col gap-4`}>
          {/* ROSTER EN PROCESO */}
          <RosterProcesoCard
            rosters={rosters}
            query={rosterQuery}
            tipo="proceso"
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <FontAwesome name="check-circle" size={24} color="green" />
              <Text style={tw`text-lg font-semibold`}> Roster en proceso</Text>
            </View>
          </RosterProcesoCard>

          {/* PROXIMO ROSTER */}
          <RosterProcesoCard
            rosters={proximosRosters}
            query={proximosRostersQuery}
            tipo="proximo"
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <MaterialCommunityIcons
                name="clock-outline"
                size={24}
                color="goldenrod"
              />
              <Text style={tw`text-lg font-semibold`}> Próximos roster</Text>
            </View>
          </RosterProcesoCard>

          {/* ROSTER FINALIZADO */}
          <RosterProcesoCard
            rosters={RostersFinalizados}
            query={RostersFinalizadosQuery}
            tipo="finalizado"
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <FontAwesome name="check" size={24} color="lightblue" />
              <Text style={tw`text-lg font-semibold`}> Roster finalizados</Text>
            </View>
          </RosterProcesoCard>
        </View>
      </PageContainer>

      <AwesomeAlert
        show={awesomeAlert.show}
        showProgress={awesomeAlert.showProgress}
        title={awesomeAlert.title}
        message={awesomeAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={awesomeAlert.showCancelButton}
        showConfirmButton={awesomeAlert.showConfirmButton}
        cancelText="Cancelar"
        confirmText={awesomeAlert.confirmText}
        confirmButtonColor={awesomeAlert.confirmButtonColor}
        customView={awesomeAlert.customView}
        onCancelPressed={() => {
          resetAwesomeAlert();
        }}
        onConfirmPressed={awesomeAlert.onConfirmPressed}
      />
    </>
  );
}
