import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import React from "react";
import { FlatList, SafeAreaView, ScrollView, Text, useWindowDimensions, View, Image } from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import { useQuery } from "react-query";
import tw from 'twrnc';
import { FontAwesome } from "@expo/vector-icons";
import { FloatingAction } from "react-native-floating-action";
import { MaterialCommunityIcons } from '@expo/vector-icons';

import { Loader } from "../../components/Loader";
import Licencia from "../../components/Licencias/Licencia";
import { getMisLicencias, misLicencias } from "../../services/apiClient";
import { awesomeAlertAtom, userAtom } from "../../store/store";
import { PageContainer } from "../../components/PageContainer";
import { useNavigation } from "@react-navigation/native";
import { Colores } from "../../constants/colores";

function MisLicenciaScreen() {
  const navigator = useNavigation();
  const { width } = useWindowDimensions();
  const [user] = useAtom(userAtom);
  const [mostrarAlert,] = useAtom(awesomeAlertAtom);
  const resetAlert = useResetAtom(awesomeAlertAtom);


  const misLicenciasQuery = useQuery("misLicencias",
    getMisLicencias
  );

  const renderItem = ({ item }) => (
    <Licencia
      licenciaDatos={item}
    />
  );

  return (
    <>
      <PageContainer paddingMd >
        <ScrollView>
          <View style={width >= 758 ? tw`pb-50 rounded-lg` : tw``}>
            {misLicenciasQuery.isLoading ? (
              <Loader text="Cargando licencias" />
            ) : misLicenciasQuery.isError ? (
              <Text>Se produjo un error</Text>
            ) : misLicenciasQuery.data?.data?.licencias.length ? (
              <View>
                <View style={width >= 758 ? tw`ml-5 my-5` : tw`items-center mt-3 mb-2`}>
                  <Text style={tw`text-lg font-semibold`}>Mis Licencias</Text>
                </View>
                <View style={width >= 758 ? tw`px-20` : tw``}>
                  <FlatList
                    data={misLicenciasQuery.data.data.licencias}
                    renderItem={renderItem}
                    keyExtractor={(item) => item.id}
                  />
                </View>
              </View>
            ) : (
              <View style={tw`items-center mt-15`}>
              <Image
                source={require("../../assets/empty.png")}
                style={[
                  width > 768 ? tw`w-full h-100 mt-10` : tw`w-50 h-50`,
                  { resizeMode: "contain" },
                ]}
              />
              <Text
                style={[
                  width > 768
                    ? tw`text-2xl mt-20 font-semibold`
                    : tw`text-xl mt-5 font-semibold`,
                  { color: "#a2a9b8" },
                ]}
              >
                Aún no solicitaste licencias
              </Text>
            </View>
            )}
          </View>
        </ScrollView>

      </PageContainer>
      <AwesomeAlert
        show={mostrarAlert.show}
        showProgress={false}
        title={mostrarAlert.title}
        message={mostrarAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={mostrarAlert.showConfirmButton}
        confirmText="Aceptar"
        customView={mostrarAlert.customView}
        confirmButtonColor={Colores.success}
        onDismiss={resetAlert}
        onCancelPressed={resetAlert}
        onConfirmPressed={resetAlert}
      />
      <FloatingAction
        color={Colores.primary}
        // onPressItem={name => {
        //   console.log(`selected button: ${name}`);
        // }}
        style={{ position: 'absolute' }}
        floatingIcon={<MaterialCommunityIcons name="plus" size={20} color="white" />}
        onPressMain={() => navigator.navigate('Solicitar Licencia')}
        showBackground={false}
      />
    </>
  );
}

export default MisLicenciaScreen;
