import React from "react";
import { Image, ImageBackground, View, Text } from "react-native";
import tw from "twrnc";
import LineasBackgroud from "./LineasBackgroud";

const LayoutMobile = ({ children }) => {
  return (
    <View style={[tw`flex-1 flex flex-col justify-between bg-white`]}>
      <ImageBackground
        source={ require("../../assets/Invitacion/Imagen_derecha_app.png")
        }
        style={tw`flex-1 px-1 py-2`}
      >
        <View style={tw`w-1/2  h-24 pt-10`}>
          <Image contentFit="contain" style={tw`w-full h-full`} source={require('../../assets/workie_logo.png')} />
        </View>
      </ImageBackground>

      <View
        style={tw`flex-1 p-4 flex flex-col items-start justify-center gap-4`}
      >
        <Text style={tw`text-2xl font-bold text-purple-900`}>¡Bienvenido!</Text>
        <Text style={tw`text-xl font-semibold text-purple-900`}>
          Accede a tu portal de empresa
        </Text>
        <Text>
          Escribe el <Text style={tw`font-bold`}>código de empresa</Text> para
          acceder al portal personalizado
        </Text>
        {children}
        <Text style={tw`text-sm text-center text-slate-700`}>
          En caso de no recordar el código, deberás consultar con el área de
          RR.HH de tu empresa.
        </Text>
      </View>
      <View stlye={tw``}>
        <LineasBackgroud style={tw`h-20`} />
      </View>
    </View>
  );
};

export default LayoutMobile;
