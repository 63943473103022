import React from "react";
import { View } from "react-native";
import tw from 'twrnc';

import { PageContainer } from "../../components/PageContainer";
import { RosterForm } from "../../forms/RosterForm";

function CrearRosterScreen(props) {
  return (
    <PageContainer paddingMd >
      <View style={tw`w-full h-full`}>
        <RosterForm {...props} />
      </View>
    </PageContainer>
  );
}

export default CrearRosterScreen;
