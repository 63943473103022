import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  FlatList,
  CheckBox,
  TextInput,
  useWindowDimensions,
  ScrollView,
} from "react-native";
import { useMutation, useQuery, useQueryClient } from "react-query";
import tw from "twrnc";
import {
  getColaboradoresParaRoster,
  agregarColaboradorARoster,
} from "../services/apiClient";
import ColaboradorAvatar from "../components/Colaboradores/ColaboradorAvatar";
import AwesomeAlert from "react-native-awesome-alerts";
import { Colores } from "../constants/colores";
import { useNavigation } from "@react-navigation/native";
import { NavigationContainer } from "@react-navigation/native";
import { Loader } from "../components/Loader";
import { AntDesign } from "@expo/vector-icons";

export const RosterColaboradorForm = (props) => {
  const queryClient = useQueryClient();
  const navigator = useNavigation();
  const { id: rosterId, colaboradores: colaboradoresEnRoster } =
    props.route.params.roster;
  const [colaboradores, setColaboradores] = useState([]);
  const [seleccionados, setSeleccionados] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState("");
  const [filteredData, setFilteredData] = useState(colaboradores);
  const [isLoading, setIsLoading] = useState(false);
  const [responseError, setResponseError] = useState();

  const colaboradoresParaRosterQuery = useQuery(
    "colaboradoresParaRoster",
    getColaboradoresParaRoster
  );

  useEffect(() => {
    if (
      colaboradoresParaRosterQuery.data &&
      colaboradoresParaRosterQuery.data.data?.ok
    ) {
      setColaboradores(colaboradoresParaRosterQuery.data.data.colaboradores);
      setFilteredData(colaboradoresParaRosterQuery.data.data.colaboradores);
    }
  }, [colaboradoresParaRosterQuery.data]);

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (colaboradoresEnRoster) {
      setSeleccionados(colaboradoresEnRoster);
    }
  }, [rosterId, props]);

  const handleColaboradorSeleccionado = (colaborador) => {
    const nuevoArray = seleccionados?.find(({ id }) => id === colaborador.id)
      ? seleccionados.filter(({ id }) => id !== colaborador.id)
      : [...seleccionados, colaborador];
    setSeleccionados(nuevoArray);
  };

  const handleColaboradorEliminado = (colaborador) => {
    const nuevoArray = seleccionados.filter(({ id }) => id !== colaborador.id);
    setSeleccionados(nuevoArray);
  };

  const cambiarVista = () => {
    navigator.navigate("Administrar Roster");
  };

  const asignarColaboradoresMutate = useMutation(agregarColaboradorARoster, {
    onSuccess: async (response) => {
      queryClient.invalidateQueries("roster");
      queryClient.invalidateQueries("proximosRosters");
      queryClient.invalidateQueries("RostersFinalizados");
      queryClient.invalidateQueries("colaboradoresParaRosterQuery");
      setSeleccionados([]);
      colaboradoresParaRosterQuery.refetch();
      cambiarVista();
      setmostrarAlert({
        show: true,
        showConfirmButton: true,
        customView: (
          <ResultadoModal success={true} message="El roster fue creado" />
        ),
      });
      setIsLoading(false);
    },
    onError: async (error) => {
      if (error.response.status === 403) {
        setmostrarAlert({
          show: true,
          customView: (
            <ResultadoModal
              success={false}
              message={error.response?.data?.message}
            />
          ),
        });
        return;
      }

      let mensajeErrores = "";
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        mensajeErrores = mensajeErrores + " \n" + value;
      }
      setResponseError(mensajeErrores);
      setIsLoading(false);
    },
  });

  const onSubmit = () => {
    const data = {
      colaborador_id: seleccionados
        .map((colaborador) => colaborador.id)
        .join(","),
    };
    asignarColaboradoresMutate.mutate({ rosterId, data });
    console.log("DATA onSubmit:", seleccionados);
  };

  const renderItem = ({ item }) => (
    <View style={tw`mt-2 flex-row items-center justify-between my-4`}>
      <View style={tw`flex flex-row items-center gap-2`}>
        <ColaboradorAvatar size={32} foto={item.foto} key={item.id} />
        <Text>
          {item.nombre} {item.apellido}
        </Text>
      </View>
      <CheckBox
        value={seleccionados?.find((colaborador) => colaborador.id === item.id)}
        onChange={() => handleColaboradorSeleccionado(item)}
      />
    </View>
  );

  const handleFilter = () => {
    const filtered = colaboradores.filter(
      (item) =>
        item.nombre.toLowerCase().includes(filterCriteria.toLowerCase()) ||
        item.apellido.toLowerCase().includes(filterCriteria.toLowerCase())
    );

    setFilteredData(filtered || []);
  };

  useEffect(() => {
    handleFilter();
  }, [filterCriteria]);

  const handleInputChange = (text) => {
    setFilterCriteria(text);
  };

  return (
    <View
      style={[
        tw`flex flex-col`,
        width >= 768 ? tw`px-15 py-5 bg-white rounded-xl` : tw`px-4 bg-white`,
      ]}
    >
      {width >= 768 && (
        <View
          style={[
            tw`w-full justify-center bg-white`,
            { borderTopLeftRadius: 20, borderTopRightRadius: 20 },
          ]}
        >
          <Text style={tw`text-lg font-semibold text-gray-600 mb-5`}>
            Seleccionar colaboradores para el roster
          </Text>
        </View>
      )}

      {seleccionados.length ? (
        <View>
          <Text style={tw`text-lg font-semibold`}>Selccionados</Text>
          <FlatList
            style={tw`bg-gray-100 my-4 p-8`}
            horizontal={true}
            data={seleccionados}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => (
              <View
                style={tw`relative w-[120px] h-[100px] flex flex-col gap-2 items-center justify-center rounded-lg border border-gray-300 bg-white mx-2 p-2`}
              >
                <ColaboradorAvatar size={32} foto={item.foto} key={item.id} />
                <Text style={tw`text-center`}>
                  {item.nombre} {item.apellido}
                </Text>
                <TouchableOpacity
                  onPress={() => handleColaboradorEliminado(item)}
                  style={tw`absolute -bottom-4 -right-4 rounded-full flex items-center justify-center bg-gray-300 p-2`}
                >
                  <AntDesign name="close" size={16} color="black" />
                </TouchableOpacity>
              </View>
            )}
          />
          <View style={tw`items-center justify-center mb-10`}>
            <TouchableOpacity
              enabled={!asignarColaboradoresMutate.isLoading}
              onPress={onSubmit}
              style={[
                tw`mt-7 px-20 py-2.5 shadow-md`,
                { backgroundColor: Colores.primary, borderRadius: 15 },
              ]}
            >
              {asignarColaboradoresMutate.isLoading ? (
                <Loader color="white" />
              ) : (
                <Text style={tw`text-white text-center`}>Finalizar</Text>
              )}
            </TouchableOpacity>
          </View>
        </View>
      ) : null}

      {responseError ? (
        <View
          style={tw`flex flex-col items-center justify-center bg-red-200 border border-red-500 rounded-lg p-2 my-4`}
        >
          <Text
            style={tw`flex flex-col items-center justify-center text-red-500 text-center`}
          >
            {responseError}
          </Text>
        </View>
      ) : null}

      {/* listado de usuarios */}
      <FlatList
        ListHeaderComponent={
          <TextInput
            style={tw`px-4 py-2 rounded-lg border border-gray-300`}
            placeholder="Buscar..."
            value={filterCriteria}
            onChangeText={(text) => {
              handleInputChange(text);
            }}
          />
        }
        data={filteredData}
        keyExtractor={(item) => item.id}
        renderItem={renderItem}
      />

      {colaboradoresParaRosterQuery.isLoading ? (
        <Loader text="Cargando colaboradores" />
      ) : null}

      {/* listado de usuarios */}
    </View>
  );
};
