import React, { useState } from "react";
import {
  Text,
  View,
  TextInput,
  ActivityIndicator,
  Image,
  Pressable,
  Switch,
  TouchableOpacity,
  ScrollView,
  StyleSheet,
  Modal
} from "react-native";
import { useForm, Controller } from "react-hook-form";
import { QueryClient, useMutation, useQueryClient } from "react-query";
import * as DocumentPicker from "expo-document-picker";
import { Entypo } from "@expo/vector-icons";
import tw from 'twrnc';
import { FontAwesome5 } from "@expo/vector-icons";
import { AntDesign } from '@expo/vector-icons';

import { nuevoAviso } from "../services/apiClient";
import { MultiSelectEmpresas } from "../components/MultiSelect";
import { useAtom } from "jotai";

import { empresasSeleccionadasAtom, idsEmpresasAtom } from "../store/store";
import AwesomeAlert from "react-native-awesome-alerts";
import ResultadoModal from "../components/ResultadoModal";
import MensajeError from "../components/MensajeError";
import { Colores } from "../constants/colores";
import { Ionicons } from '@expo/vector-icons';
import { esWeb } from "../utils/plataforma";

export const HistoriaForm = (props) => {
  const {navigation} = props;
  const [foto, setfoto] = useState();
  const [, setEmpresasSeleccionadas] = useAtom(empresasSeleccionadasAtom);
  const [modalVisible, setModalVisible] = useState(false);
  const [idsEmpresas, setidsEmpresas] = useAtom(idsEmpresasAtom);
  const [esTemporal, setesTemporal] = useState(1);
  const [imagen, setImagen] = useState(false)
  const [agregarTexto, setAgregarTexto] = useState(true)
  const toggleSwitch = () => setIsEnabled(previousState => !previousState);
  const queryClient = useQueryClient();
  

  

  const alertInitialState = {
    show: false,
    title: "",
    message: "",
    showConfirmButton: false,
  };
  const [mostrarAlert, setmostrarAlert] = useState(alertInitialState);

  const toggleEsTemporalSwitch = () =>
    setesTemporal();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const resetForm = () => {
    //reset form
    reset({ titulo: "", texto: "" });
    setfoto();
    setesTemporal();
    setidsEmpresas();
    //resetear multiselect
    setEmpresasSeleccionadas([]);
    setAgregarTexto(true);
    setModalVisible(false)
  };

  const nuevoAvisoMutate = useMutation(nuevoAviso, {
    onSuccess: async (response) => {
      queryClient.invalidateQueries("historias");
      resetForm();
      //mostral mostrar modal
      setmostrarAlert({
        show: true,
        showConfirmButton: true,
        customView: (
          <ResultadoModal success={true} message="La historia fue creada" />
        ),
      });
    },
    onError: async (error) => {
      console.log("error", error.response.data);
      let mensajeErrores = "";
      for (const [key, value] of Object.entries(error.response.data.errors)) {
         mensajeErrores = mensajeErrores + " \n" + value;
      }
      setmostrarAlert({
        show: true,
        customView: <ResultadoModal success={false} message={mensajeErrores} />,
      });
    },
  });

  const formData = new FormData();
  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: ["image/jpeg", "image/png"],
    });
    if (result.type === 'success') {
      setfoto(result);
      console.log(result)
    }
  };

  const onSubmit = (data) => {
    
    formData.append("unica", 1);
    if (foto) {
      if (esWeb()) {
        formData.append("foto[]", foto?.file);
      }else{
        const archivoImagen =  {
          uri: foto?.uri,
          name: foto?.name,
          type: foto?.mimeType,
        };
        formData.append("foto[]", archivoImagen);
      }
    }
    console.log("formdata", formData)
    if (idsEmpresas) {
      formData.append("empresas", idsEmpresas);
    }
    //se cargan el resto de campos del formulario en formData
    for (const key in data) {
      if (key === "field" && data[key][1]) {
        formData.append(key, data[key][1]);
      } else if(data[key]) {
        formData.append(key, data[key]);
      }
    }

    nuevoAvisoMutate.mutate(formData);
  };

  return (
    <View style={tw`flex-1`}>
      {!foto ? (

      <View style={[tw`flex-row flex-1`,{backgroundColor:Colores.primary}]}>
        <View style={tw`flex-1 justify-center`}>
          <TouchableOpacity style={tw`ml-5`} onPress={()=>navigation.navigate("Avisos")}>
            <AntDesign name="close" size={24} style={tw`text-white`} />
          </TouchableOpacity>
        </View>
        <View style={tw`flex-1 text-center justify-center`}>
          <Text style={tw`text-white text-lg`}>Nueva Historia</Text>
        </View>
        <View style={tw`flex-1`}></View>
      </View>
      ):(
      <View style={[tw`flex-row z-40`]}>
        
          <TouchableOpacity style={[tw`absolute mt-5 z-50 ml-5 p-1 rounded-full`,{backgroundColor:Colores.primaryMedium}]} onPress={()=>setfoto(undefined)}>
            <Ionicons name="arrow-back" size={24} style={tw`text-white`}/>
          </TouchableOpacity>
        
      
          <TouchableOpacity style={[tw`absolute mt-5 z-50 rounded-full px-2 py-1 right-5`,{backgroundColor:Colores.primaryMedium}]} onPress={()=>setModalVisible(true)}>
            <Text style={tw`text-white text-lg`}>Siguiente</Text>
          </TouchableOpacity>
       
      </View>

      )}


      {imagen === false && (
        <Pressable onPress={pickDocument} foreground={true} style={tw`flex-12`}>
          <View style={tw`flex-1 my-1 px-2`}>
                {foto ? (
                  <Image
                    style={[tw`flex-1 rounded-xl`,{resizeMode:'contain', borderRadius:20, backgroundColor:Colores.primaryLight}]}
                    source={{ uri: foto.uri }}
                  />
                ):(  
                  <View style={[tw`flex-1 rounded-lg items-center justify-center`,{backgroundColor:Colores.primaryLight}]}>
                    <Text>
                      Agregar imagen
                    </Text>
                  </View>
                )}
          </View>
        </Pressable>
      )}

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
      >
        <View style={[tw`w-full h-full`]}>
          <Pressable style={{height:agregarTexto ? '50%' : '70%'}} onPress={()=>setModalVisible(false)} />

          <View style={[tw`w-full bottom-0 bg-white absolute`,{borderTopLeftRadius:50, borderTopRightRadius:50, height:agregarTexto ? '50%' : '30%'}]}>
                <ScrollView>
                    <View style={tw`p-3`}>

                      <View style={tw`items-center`}>
                        <View style={tw`flex-row my-3`}>
                          <Text style={tw`font-semibold mr-2`}>
                            Agregar titulo/descripción
                          </Text>
                          <Switch
                            trackColor={{ false: "#767577", true: "#81b0ff" }}
                            thumbColor={agregarTexto ? "#f5dd4b" : "#f4f3f4"}
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={setAgregarTexto}
                            value={agregarTexto}
                          />
                        </View>
                      </View>

                      {agregarTexto && (

                        <View>
                            <Controller
                              control={control}
                              rules={{
                                required: "Ingrese un titulo",
                              }}
                              render={({ field: { onChange, onBlur, value } }) => (
                                <TextInput
                                  style={tw` w-full px-3 mt-2 py-1.5 text-gray-500 rounded-full border border-gray-200`}
                                  onBlur={onBlur}
                                  onChangeText={onChange}
                                  placeholder="Escribir un titulo para la foto o video"
                                  value={value}
                                />
                              )}
                              name="titulo"
                            />
                            {errors?.titulo && <MensajeError message={errors.titulo?.message} />}
                              
                            <Controller
                              control={control}
                              rules={{
                                required: "Ingrese el texto",
                              }}
                              render={({ field: { onChange, onBlur, value } }) => (
                                <TextInput
                                  style={tw` w-full h-30 px-3 mt-2 py-1.5 text-gray-500 rounded-lg border border-gray-200`}
                                  onBlur={onBlur}
                                  onChangeText={onChange}
                                  multiline={true}
                                  value={value}
                                  placeholder="Descripción"
                                />
                              )}
                              name="texto"
                            />
                            {errors?.texto && <MensajeError message={errors.texto?.message} />}
                        </View>

                      )}
                        
                      <View style={tw`hidden`}>
                        <Controller
                          control={control}
                          defaultValue={esTemporal}
                          name="esTemporal"
                          render={({ field: { onChange, onBlur, value } }) => (
                            <View style={tw`flex-row flex my-5`}>
                              <Switch
                                trackColor={{ false: "#767577", true: "#81b0ff" }}
                                thumbColor={value ? "#f5dd4b" : "#f4f3f4"}
                                ios_backgroundColor="#3e3e3e"
                                onValueChange={(value) => {
                                  toggleEsTemporalSwitch;
                                  onChange(value ? 1 : 0);
                                }}
                                value={value}
                                style={tw`mt-1`}
                              />
                              <Text style={tw`ml-3 text-gray-700 text-lg`}>Subir como historia</Text>
                            </View>
                          )}
                        />
                      </View>
                            
                            
                      <View style={tw`mt-3`}>
                        <MultiSelectEmpresas />
                      </View>
                      
                      {nuevoAvisoMutate.isLoading && !nuevoAvisoMutate.isError? (
                      <ActivityIndicator size="small" color={Colores.primary} />
                        ) : (
                        <View style={tw`items-center justify-center mt-5 mb-10`}>
                          <TouchableOpacity style={[tw`ml-1 px-20 py-2.5 shadow-md`,{backgroundColor:Colores.primary, borderRadius:15}]} onPress={handleSubmit(onSubmit)}>
                            <Text style={tw`text-white text-center`}>
                              Publicar
                            </Text>
                          </TouchableOpacity>
                          <TouchableOpacity style={tw`mt-3`} onPress={()=>setModalVisible(false)}>
                            <Text style={[tw`text-center`,{color:Colores.primary}]}>
                              Cancelar
                            </Text>
                          </TouchableOpacity>
                        </View>
                      )}

                    </View>
                </ScrollView>
            </View>
        </View>
      </Modal>
        


      <AwesomeAlert
        show={mostrarAlert.show}
        showProgress={false}
        title={mostrarAlert.title}
        message={mostrarAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={mostrarAlert.showConfirmButton}
        confirmText="Aceptar"
        customView={mostrarAlert.customView}
        confirmButtonColor={Colores.success}
        onDismiss={() => {
          setmostrarAlert(alertInitialState);
        }}
        onCancelPressed={() => {
          setmostrarAlert(alertInitialState);
        }}
        onConfirmPressed={() => {
          setmostrarAlert(alertInitialState);
          props.navigation.navigate("Avisos");
        }}
      />

    </View>
  );
};