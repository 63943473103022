import React from "react";
import { View } from "react-native";

import { PageContainer } from "../../components/PageContainer";
import { RosterEditarForm } from "../../forms/RosterEditarForm";

function EditarRosterScreen(props) {
  return (
    <PageContainer paddingMd>
      <View style={{ padding: "10px" }}>
        <RosterEditarForm {...props} />
      </View>
    </PageContainer>
  );
}

export default EditarRosterScreen;
