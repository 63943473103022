import { View, Text } from "react-native";
import React from "react";
import tw from "twrnc";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from "react-native-popup-menu";
import { eliminarRoster } from "../../services/apiClient";
import { useMutation, useQueryClient } from "react-query";
import { FontAwesome5 } from "@expo/vector-icons";
import { Colores } from "../../constants/colores";
import { useResetAtom } from "jotai/utils";
import { awesomeAlertAtom } from "../../store/store";
import { useAtom } from "jotai";
import { useNavigation } from "@react-navigation/core";
import ResultadoModal from "../ResultadoModal";

const RosterProcesoPreviewCard = ({ roster }) => {
  const navigation = useNavigation();
  const queryClient = useQueryClient();
  let fecha_inicio = new Date(roster.fecha_inicio);
  let fecha_fin = new Date(roster.fecha_fin);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);
  const [_, setawesomeAlert] = useAtom(awesomeAlertAtom);

  const confirmar = () => {
    setawesomeAlert({
      show: true,
      showProgress: eliminarMutate.isLoading,
      title: "Atención",
      message: "Esta seguro de desea eliminar el roster?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmText: "Eliminar",
      confirmButtonColor: "#DD6B55",
      onConfirmPressed: () => {
        eliminarMutate.mutate(roster.id);
      },
      onDismiss: () => {
        resetAwesomeAlert();
      },
      onSettled: (data) => {
        if (data.data.ok) {
          resetAwesomeAlert();
        }
      },
    });
  };

  const eliminarMutate = useMutation(eliminarRoster, {
    onSuccess: async (response) => {
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Exito!",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal success={true} message={response.data.message} />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
      queryClient.invalidateQueries("roster");
      queryClient.invalidateQueries("proximosRosters");
      queryClient.invalidateQueries("RostersFinalizados");
    },
    onError: async (error) => {
      let mensajeErrores = "";
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        mensajeErrores = mensajeErrores + " \n" + value;
      }

      //setmostrarAlerta(false)
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: <ResultadoModal success={false} message={mensajeErrores} />,
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
    },
  });

  return (
    <View
      style={tw`flex flex-col bg-white border border-gray-200 rounded-lg shadow-md my-2`}
    >
      <View
        style={tw`flex flex-row justify-between p-4 bg-gray-200 rounded-t-lg`}
      >
        <Text style={tw`text-lg font-semibold`}>{roster?.nombre}</Text>
        <Menu renderer={renderers.NotAnimatedContextMenu}>
          <MenuTrigger>
            <FontAwesome5 name="ellipsis-v" size={17} color={Colores.primary} />
          </MenuTrigger>
          <MenuOptions
            style={tw`p-1`}
            optionsContainerStyle={{ borderRadius: 10, marginTop: 20 }}
          >
            <MenuOption
              onSelect={() => {
                navigation.navigate("Nuevo Roster", {
                  roster,
                });
                roster = null;
              }}
              style={tw`flex flex-row px-2 py-2`}
            >
              <FontAwesome5
                name="edit"
                size={17}
                style={{ color: Colores.primary }}
              />
              <Text style={[tw`ml-5`, { color: Colores.primary }]}>Editar</Text>
            </MenuOption>

            <MenuOption
              onSelect={() => confirmar(roster.id)}
              style={tw`flex flex-row px-2 py-2`}
            >
              <FontAwesome5
                name="trash-alt"
                size={17}
                style={{ color: Colores.primary }}
              />
              <Text style={[tw`ml-5`, { color: Colores.primary }]}>
                Eliminar
              </Text>
            </MenuOption>
          </MenuOptions>
        </Menu>
      </View>
      <View style={tw`flex flex-col bg-white gap-3 p-4`}>
        <View style={tw`flex flex-row items-center gap-2`}>
          <Text style={tw`font-semibold text-lg text-[${Colores.primary}]`}>
            Inicio:{" "}
          </Text>
          <Text style={tw`text-lg text-gray-700`}>
            {fecha_inicio.toLocaleDateString("es-AR")}
          </Text>
          <Text style={tw`font-semibold text-lg text-[${Colores.primary}]`}>
            Fin:{" "}
          </Text>
          <Text style={tw`text-lg text-gray-700`}>
            {fecha_fin.toLocaleDateString("es-AR")}
          </Text>
        </View>

        <View style={tw`flex flex-row items-center gap-4`}>
          <FontAwesome5 name="calendar" size={20} color={Colores.primary} />
          <Text style={tw`text-lg text-gray-700`}>{roster.dias} días</Text>
        </View>

        <View style={tw`flex flex-row items-center gap-4`}>
          <FontAwesome5 name="user" size={20} color={Colores.primary} />
          <Text style={tw`text-lg text-gray-700`}>
            Creador: {roster.creador ? roster.creador.nombre : "-"}
          </Text>
        </View>

        <View style={tw`flex flex-row items-center gap-4`}>
          <FontAwesome5 name="users" size={20} color={Colores.primary} />
          <Text style={tw`text-lg text-gray-700`}>
            Colaboradores: {roster.colaboradores_count ?? "-"}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default RosterProcesoPreviewCard;
