import React from "react";
import { ImageBackground, View, Text } from "react-native";
import tw from "twrnc";
import LineasBackgroud from "./LineasBackgroud";
import LogoPurple from "./LogoPurple";

const LayoutDesktop = ({ children }) => {
  return (
    <View style={[tw`flex-1 flex  bg-white`]}>
      <View style={tw`flex-1 flex flex-row items-center`}>
        <View
          style={tw`w-1/2 p-8 flex flex-col items-center justify-center gap-4`}
        >
          <View style={tw`w-1/2 flex items-center mb-20`}>
           <LogoPurple />
          </View>
          <Text style={tw`text-2xl font-bold text-purple-900`}>
            ¡Bienvenido!
          </Text>
          <Text style={tw`text-xl font-semibold text-purple-900`}>
            Accede a tu portal de empresa
          </Text>
          <Text>
            Escribe el <Text style={tw`font-bold`}>código de empresa</Text> para
            acceder al portal personalizado
          </Text>
          <View style={tw`w-1/3`}>{children}</View>
          <Text style={tw`w-2/5 text-sm text-center text-slate-700`}>
            En caso de no recordar el código, deberás consultar con el área de
            RR.HH de tu empresa.
          </Text>
        </View>

        <ImageBackground
          source={{
            uri: require("../../assets/Invitacion/Imagen_derecha.png"),
          }}
          style={tw`flex-1 w-full h-full`}
        ></ImageBackground>
      </View>
    </View>
  );
};

export default LayoutDesktop;
