import React, { useState } from "react";
import {
  Text,
  View,
  TextInput,
  Button,
  StyleSheet,
  ActivityIndicator,
  Switch,
  TouchableOpacity,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";
import tw from 'twrnc';

import { editarBeneficio } from "../services/apiClient";

import AwesomeAlert from "react-native-awesome-alerts";
import ResultadoModal from "../components/ResultadoModal";
import MensajeError from "../components/MensajeError";
import EditarImagen from "../components/EditarImagen";
import { Colores } from "../constants/colores";

export const BeneficioEditarForm = (props) => {
  const { beneficio } = props.route.params;
  const [foto, setfoto] = useState();
  const [activo, setactivo] = useState(1);
  const {width} = useWindowDimensions();

  const alertInitialState = {
    show: false,
    title: "",
    message: "",
    showConfirmButton: false,
  };

  let valoresInicialesForm = {
    beneficio: beneficio.beneficio,
    empresa: beneficio.empresa,
    descuento: beneficio.descuento,
    web: beneficio.web ?? '',
    activo: beneficio.activo,
  };
  const [mostrarAlert, setmostrarAlert] = useState(alertInitialState);

  const toggleActivoSwitch = () => setactivo((previousState) => !previousState);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: valoresInicialesForm,
  });

  const resetForm = () => {
    //reset form
    reset({ titulo: "", texto: "" });
    setfoto();
    setactivo(0);
  };

  const editarBeneficioMutate = useMutation(editarBeneficio, {
    onSuccess: async (response) => {
      resetForm();
      //mostral mostrar modal
      setmostrarAlert({
        show: true,
        showConfirmButton: true,
        customView: (
          <ResultadoModal
            success={true}
            message="El beneficio fue modificado"
          />
        ),
      });
    },
    onError: async (error) => {
      console.log(error.response.data);
      //TODO: mostrar errores en campos
      setmostrarAlert({
        show: true,
        customView: <ResultadoModal success={false} message="Hubo un error" />,
      });
    },
  });

  const formData = new FormData();

  const onSubmit = (data) => {
    if (foto) {
      formData.append("foto", foto?.file);
    }
    console.log(data);

    //se cargan el resto de campos del formulario en formData
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        console.log("solo key", key)
        if(data[key]){
          formData.append(key, data[key]);
        }
      }
    }

    editarBeneficioMutate.mutate({ beneficioId: beneficio.id, formData });
  };

  return (
    <View>      
      <View style={width >= 768 ? tw`px-15 py-10 bg-white rounded-xl` : tw`px-4 bg-white`}>
        {width >= 768 && (
        <View style={[tw`w-full justify-center bg-white`,{borderTopLeftRadius:20, borderTopRightRadius:20}]}>
            <Text style={tw`text-lg font-semibold text-gray-600 mb-5`}>Editar Beneficio</Text>
        </View>
        )}
        <View style={tw`flex flex-row w-full`}>
          <View style={tw`items-center justify-center`}>
            <EditarImagen formData={formData} modelo={beneficio} />
          </View>

          <View style={tw`pl-5`}>
            <Text style={tw` mt-3 text-lg text-gray-900`}>Empresa o servicio</Text>
            <Controller
              control={control}
              rules={{
                required: "Indique la empresa/servicio",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[tw` mt-2 w-full px-3 py-2.5 text-gray-700 border`,{borderRadius:50, borderColor:Colores.primary}]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  multiline={true}
                  placeholder="Empresa / Servicio"
                  value={value}
                />
              )}
              name="empresa"
            />
            {errors?.empresa && <MensajeError message={errors.empresa?.message} />}

            <View style={tw`flex flex-row`}>
            
              <View>
                <Text style={tw` mt-5 text-lg text-gray-900`}>Descuento</Text>
                  
                <Controller
                  control={control}
                  rules={{
                    required: "Indique el beneficio",
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      style={[tw` w-20 mt-2 px-3 py-2.5 text-gray-700 border`,{borderRadius:50, borderColor:Colores.primary}]}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      placeholder="Beneficio"
                      value={value}
                    />
                  )}
                  name="descuento"
                />
                  {errors?.descuento && (
                  <MensajeError message={errors.descuento?.message} />
                )}
              </View>

              <View style={tw`items-center justify-center`}>
                <Controller
                  control={control}
                  defaultValue={activo}
                  name="activo"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View style={tw`flex-row flex mt-5`}>
                      <Text style={tw`ml-3 text-gray-700 text-lg`}>Activo</Text>
                      <Switch
                        trackColor={{ false: "#767577", true: "#81b0ff" }}
                        thumbColor={value ? "#f5dd4b" : "#f4f3f4"}
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={(value) => {
                          toggleActivoSwitch;
                          onChange(value ? 1 : 0);
                        }}
                        value={value}
                        style={tw`ml-1 mt-1`}
                      />
                    </View>
                  )}
                />
              </View>
            </View>
            </View>
        </View>

       

        
        <Text style={tw` mt-5 text-lg text-gray-900`}>Indique el beneficio</Text> 
        <Controller
          control={control}
          rules={
            {
              //required: 'Indique el descuento'
            }
          }
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              keyboardType="number-pad"
              style={[tw` h-50 mt-2 w-full px-3 py-2 text-gray-700 border border-gray-400`,{borderRadius:10}]}
              onBlur={onBlur}
              onChangeText={onChange}
              multiline={true}
              value={value}
            />
          )}
          name="beneficio"
        />
         {errors?.beneficio && (
          <MensajeError message={errors.beneficio?.message} />
        )}

        <Text style={tw` mt-5 text-lg text-gray-900`}>Direccion WEB (Opcional)</Text>

        <Controller
          control={control}
          rules={
            {
              //required: 'Indique una dirección url'
            }
          }
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={[tw` mt-2 w-full px-3 py-2.5 text-gray-700 border border-gray-400`,{borderRadius:50}]}
              onBlur={onBlur}
              onChangeText={onChange}
              multiline={true}
              placeholder="Direccion WEB (Opcional)"
              value={value}
            />
          )}
          name="web"
        />
        {errors?.web && <MensajeError message={errors.web?.message} />}


        {editarBeneficioMutate.isLoading ? (
          <ActivityIndicator size="small" color={Colores.primary} />
        ) : (
          <View style={tw`items-center justify-center`}>
            <TouchableOpacity style={[tw`mt-5 px-20 py-2.5 rounded shadow-md`,{backgroundColor:Colores.primary, borderRadius:16}]} onPress={handleSubmit(onSubmit)}>
              <Text style={tw`text-white text-center`}>Guardar</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>

      <AwesomeAlert
        show={mostrarAlert.show}
        showProgress={false}
        title={mostrarAlert.title}
        message={mostrarAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={mostrarAlert.showConfirmButton}
        confirmText="Aceptar"
        customView={mostrarAlert.customView}
        confirmButtonColor={Colores.success}
        onDismiss={() => {
          setmostrarAlert(alertInitialState);
          props.navigation.navigate("Administrar Beneficios");
        }}
        onCancelPressed={() => {
          setmostrarAlert(alertInitialState);
        }}
        onConfirmPressed={() => {
          setmostrarAlert(alertInitialState);
          props.navigation.navigate("Administrar Beneficios");
        }}
      />

    </View>
  );
};