import React, { useState } from "react";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { SafeAreaView, ScrollView, Text, useWindowDimensions, View } from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import tw from 'twrnc';

import LicenciaDetalle from "../../components/Licencias/LicenciaDetalle";
import LicenciaAutorizarForm from "../../forms/LicenciaAutorizarForm";
import { awesomeAlertAtom } from "../../store/store";
import { PageContainer } from "../../components/PageContainer";
import { Colores } from "../../constants/colores";

function EditarLicenciaScreen(props) {
  const { licencia } = props.route.params;
  const {width} = useWindowDimensions();
  const [estadoLicencia, setEstadoLicencia] = useState()
  const [mostrarAlert,] = useAtom(awesomeAlertAtom);
  const resetAlert = useResetAtom(awesomeAlertAtom);

  const cambiarEstado = (estado) =>{
    setEstadoLicencia(estado)
  }

  return (
    <PageContainer paddingMd >
      <ScrollView contentContainerStyle={{ flexGrow: 1, padding: 16 }}>
        <View style={ width ? tw`bg-white p-10 rounded-lg` : tw`bg-white` }>
          <View>
            {width >=768 && (
              <Text style={tw`text-lg font-semibold mb-5`}>Detalle de la licencia</Text>
            )}
          </View>
          <Text style={tw`text-lg ml-1`}>Licencia</Text>
          <LicenciaDetalle licenciaDatos={licencia} estadoLicencia={estadoLicencia} />
          <LicenciaAutorizarForm {...props} licencia={licencia} cambiarEstado={cambiarEstado} />
        </View>
        
      </ScrollView>

      <AwesomeAlert
        show={mostrarAlert.show}
        showProgress={false}
        title={mostrarAlert.title}
        message={mostrarAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={mostrarAlert.showConfirmButton}
        confirmText="Aceptar"
        customView={mostrarAlert.customView}
        confirmButtonColor={Colores.success}
        onDismiss={resetAlert}
        onCancelPressed={resetAlert}
        onConfirmPressed={mostrarAlert.onConfirmPressed}
      />
    </PageContainer>
  );
}

export default EditarLicenciaScreen;
