import React from "react";
import { View, Text, ScrollView, useWindowDimensions } from "react-native";
import tw from "twrnc";
import { MaterialIcons } from "@expo/vector-icons";
import { Colores } from "../../constants/colores";
import ItemCard from "../../components/Panel/ItemCard";
import { Ionicons } from "@expo/vector-icons";
import { FontAwesome } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import { Feather } from "@expo/vector-icons";
import { PageContainer } from "../../components/PageContainer";
import { useAtom } from "jotai";
import { userAtom } from "../../store/store";

const PanelControlScreen = () => {
  const { width } = useWindowDimensions();
  const [user, _] = useAtom(userAtom);

  const checkPermisos = (listaSlugPermisos) => {
    const tienePermiso = user.permisos.some((permiso) =>
      listaSlugPermisos.includes(permiso.slug)
    );

    return tienePermiso;
  };

  return (
    <PageContainer>
      <View
        nativeID="drawerContainer"
        style={
          width >= 768
            ? [
                tw`m-10 p-5 rounded-xl`,
                { backgroundColor: Colores.primaryLight },
              ]
            : [tw`m-0 p-5`, { backgroundColor: Colores.primaryLight }]
        }
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          <View style={tw`pt-5 pb-5`}>
            <Text style={tw`text-center text-xl`}>Panel de control</Text>

            <View style={tw`flex-wrap flex-row justify-evenly`}>
              {checkPermisos([
                "administrar.todo",
                "administrar.avisos",
                "editar.avisos",
                "eliminar.avisos",
              ]) ? (
                <View style={tw`mt-5 w-80 z-50`}>
                  <ItemCard
                    ruta="Administrar Avisos"
                    icon={
                      <Feather
                        name="edit"
                        style={tw`mb-2`}
                        size={24}
                        color={Colores.primaryMedium}
                      />
                    }
                    title={"Publicaciones"}
                  />
                </View>
              ) : null}

              {checkPermisos(["administrar.todo"]) ? (
                <View style={tw`mt-5 w-80 z-50`}>
                  <ItemCard
                    ruta="Administrar Roster"
                    icon={
                      <Feather
                        name="edit"
                        style={tw`mb-2`}
                        size={24}
                        color={Colores.primaryMedium}
                      />
                    }
                    title={"Roster"}
                  />
                </View>
              ) : null}

              {checkPermisos([
                "administrar.todo",
                "administrar.menus",
                "editar.menu",
                "eliminar.menu",
              ]) ? (
                <View style={tw`mt-5 w-80 z-50`}>
                  <ItemCard
                    ruta="Almuerzos"
                    icon={
                      <FontAwesome
                        name="cutlery"
                        size={24}
                        style={tw`mb-2`}
                        color={Colores.primaryMedium}
                      />
                    }
                    title={"Almuerzos"}
                  />
                </View>
              ) : null}

              {checkPermisos([
                "administrar.todo",
                "administrar.sugerencias",
                "editar.sugerencia",
                "eliminar.sugerencia",
                "ver.sugerencia",
              ]) ? (
                <View style={tw`mt-5 w-80 z-50`}>
                  <ItemCard
                    ruta="Administrar Sugerencias"
                    icon={
                      <MaterialIcons
                        name="move-to-inbox"
                        style={tw`mb-2`}
                        size={24}
                        color={Colores.primaryMedium}
                      />
                    }
                    title={"Buzon"}
                  />
                </View>
              ) : null}

              {checkPermisos([
                "administrar.todo",
                "administrar.beneficios",
                "editar.beneficio",
                "eliminar.beneficio",
              ]) ? (
                <View style={tw`mt-5 w-80 z-50`}>
                  <ItemCard
                    ruta="Administrar Beneficios"
                    icon={
                      <MaterialCommunityIcons
                        name="ticket-percent"
                        style={tw`mb-2`}
                        size={24}
                        color={Colores.primaryMedium}
                      />
                    }
                    title={"Beneficios"}
                  />
                </View>
              ) : null}

              {checkPermisos([
                "administrar.todo",
                "administrar.recibos",
                "editar.recibo",
                "eliminar.recibo",
              ]) ? (
                <View style={tw`mt-5 w-80 z-50`}>
                  <ItemCard
                    ruta="Cargar Recibos"
                    icon={
                      <Ionicons
                        name="ios-receipt"
                        style={tw`mb-2`}
                        size={24}
                        color={Colores.primaryMedium}
                      />
                    }
                    title={"Recibos"}
                  />
                </View>
              ) : null}

              {checkPermisos([
                "administrar.todo",
                "administrar.colaboradores",
                "editar.colaborador",
                "eliminar.colaborador",
                "ver.colaborador",
              ]) ? (
                <View style={tw`mt-5 w-80 z-50`}>
                  <ItemCard
                    ruta="Administrar Colaboradores"
                    icon={
                      <FontAwesome5
                        name="users"
                        style={tw`mb-2`}
                        size={24}
                        color={Colores.primaryMedium}
                      />
                    }
                    title={"Colaboradores"}
                  />
                </View>
              ) : null}

              {checkPermisos([
                "administrar.todo",
                "administrar.carreras",
                "editar.carrera",
                "eliminar.carrera",
                "ver.carrera",
              ]) ? (
                <View style={tw`mt-5 w-80 z-50`}>
                  <ItemCard
                    ruta="Administrar Plan De Carrera"
                    icon={
                      <MaterialIcons
                        name="school"
                        style={tw`mb-2`}
                        size={24}
                        color={Colores.primaryMedium}
                      />
                    }
                    title={"Carreras"}
                  />
                </View>
              ) : null}

              {checkPermisos([
                "administrar.todo",
                "administrar.licencias",
                "eliminar.licencia",
                "ver.licencia",
              ]) ? (
                <View style={tw`mt-5 w-80 z-50`}>
                  <ItemCard
                    ruta="Administrar Licencias"
                    icon={
                      <Ionicons
                        name="ios-document-text"
                        style={tw`mb-2`}
                        size={24}
                        color={Colores.primaryMedium}
                      />
                    }
                    title={"Licencias"}
                  />
                </View>
              ) : null}

              {checkPermisos(["administrar.todo"]) ? (
                <View style={tw`mt-5 w-80 z-50`}>
                  <ItemCard
                    ruta="Codigos de Invitacion"
                    icon={
                      <Ionicons
                        name="ios-document-text"
                        style={tw`mb-2`}
                        size={24}
                        color={Colores.primaryMedium}
                      />
                    }
                    title={"Cod. Invitación"}
                  />
                </View>
              ) : null}
              {/* <View style={tw`mt-5 w-80 z-50`}><ItemCard ruta="Dashboard" icon={<Ionicons name="stats-chart-outline" style={tw`mb-2`} size={24} color={Colores.primaryMedium} />} title={'Dashboard'} /></View> */}
              {checkPermisos([
                "administrar.todo",
                "administrar.items",
                "editar.item",
                "asignar.item",
                "crear.item",
                "eliminar.item",
              ]) ? (
                <View style={tw`mt-5 w-80 z-50`}>
                  <ItemCard
                    ruta="Administrar Items"
                    icon={
                      <Ionicons
                        name="ios-document-text"
                        style={tw`mb-2`}
                        size={24}
                        color={Colores.primaryMedium}
                      />
                    }
                    title={"Activos"}
                  />
                </View>
              ) : null}
            </View>
          </View>
        </ScrollView>
      </View>
    </PageContainer>
  );
};

export default PanelControlScreen;
