import React, { useEffect, useState } from "react";
import { ImageBackground, Text, View, Image } from "react-native";
import tw from "twrnc";
import EnviarCodigoInvitacionForm from "../forms/EnviarCodigoInvitacionForm";
import LineasBackgroud from "../components/Invitacion/LineasBackgroud";
import { Dimensions } from "react-native";

import logoWhite from "../assets/workie_logo.png";
import LayoutMobile from "../components/Invitacion/LayoutMobile";
import LayoutDesktop from "../components/Invitacion/LayoutDesktop";

const CodigoInvitacionScreen = () => {
  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("window").width
  );

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(Dimensions.get("window").width);
    };

    Dimensions.addEventListener("change", updateScreenWidth);

    return () => {
      Dimensions.removeEventListener("change", updateScreenWidth);
    };
  }, []);

  if (screenWidth >= 768) {
    return (
      <LayoutDesktop>
        <EnviarCodigoInvitacionForm />
      </LayoutDesktop>
    );
  } else {
    return (
      <LayoutMobile>
        <EnviarCodigoInvitacionForm />
      </LayoutMobile>
    );
  }
};

export default CodigoInvitacionScreen;
